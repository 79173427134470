import {IConfig, createOvermind} from 'overmind'
// just testing a new change in fe code
import {state} from './state'
import onInitialize from './onInit'
import authApi from './auth'

import * as tasks from './tasks'
import * as alarms from './alarms'
import * as appliances from './appliances'
import * as settings from './settings'
import * as documents from './documents'
import * as home from './home'
import * as generalTasks from './generalTasks'
import * as unscheduledTasks from './unscheduledTasks'
import * as performTask from './performTask'
import * as taskTemplates from './taskTemplates'
import * as instructions from './instructions'
import * as messages from './messages'

import {createHook} from 'overmind-react'
import {
  tasksApi,
  assetApi,
  userApi,
  taskGroupsApi,
  equipmentApi,
  alarmsApi,
  appStatusApi,
  adminApi,
  organisationApi,
  dashboardApi
} from './rest'

import * as actions from './actions'

const old = {
  onInitialize,
  state,
  actions,
  effects: {
    authApi,
    tasksApi,
    alarmsApi,
    assetApi,
    userApi,
    taskGroupsApi,
    equipmentApi,
    appStatusApi,
    adminApi,
    organisationApi,
    dashboardApi
  }
}

export const config = {
  onInitialize: old.onInitialize,
  state: {
    ...old.state,
    v1: {
      tasks: tasks.state,
      alarms: alarms.state,
      appliances: appliances.state,
      settings: {
        tasks: settings.taskState,
        taskGroups: settings.taskGroupState,
        gateways: settings.gatewayState,
        suspendedPeriods: settings.suspendedPeriodState,
        appliances: settings.applianceState,
        multipleSiteSuspendedPeriod: settings.multipleSiteSuspendedPeriodState
      },
      documents: documents.documentState,
      generalTasks: generalTasks.state,
      unscheduledTasks: unscheduledTasks.state,
      home: {
        site: home.homeSiteState,
        allSites: home.allSitesState
      },
      taskTemplates: taskTemplates.state,
      instructions: instructions.InstructionsState,
      messages: messages.MessageState
    }
  },
  actions: {
    ...old.actions,
    v1: {
      tasks: tasks.actions,
      alarms: alarms.actions,
      appliances: appliances.actions,
      settings: {
        tasks: settings.taskActions,
        taskGroups: settings.taskGroupActions,
        gateways: settings.gatewayActions,
        suspendedPeriods: settings.suspendedPeriodActions,
        appliances: settings.applianceActions,
        multipleSiteSuspendedPeriod: settings.multipleSiteSuspendedPeriodActions
      },
      documents: documents.documentActions,
      generalTasks: generalTasks.actions,
      unscheduledTasks: unscheduledTasks.actions,
      performTask: performTask.actions,
      home: {
        site: home.homeSiteActions,
        allSites: home.allSitesActions
      },
      taskTemplates: taskTemplates.actions,
      instructions: instructions.instructionsActions,
      messages: messages.messageActions
    }
  },
  effects: {
    ...old.effects,
    v1: {
      tasks: tasks.effects,
      alarms: alarms.effects,
      appliances: appliances.effects,
      settings: {
        tasks: settings.taskEffects.settingsApi,
        taskGroups: settings.taskGroupEffects.tasksApi,
        gateways: settings.gatewayEffects.gatewayApi,
        suspendedPeriods: settings.suspendedPeriodEffects,
        appliances: settings.applianceEffects,
        multipleSiteSuspendedPeriod: settings.multipleSiteSuspendedPeriodEffects
      },
      documents: documents.documentEffects.documentsApi,
      generalTasks: generalTasks.effects,
      unscheduledTasks: unscheduledTasks.effects,
      performTask: performTask.effects,
      home: {
        site: home.homeSiteEffects,
        allSites: home.allSitesEffects
      },
      taskTemplates: taskTemplates.effects,
      instructions: instructions.instructionsEffects.instructionsApi,
      messages: messages.messageEffects.messagesApi
    }
  }
}

// This is implicit typing, so remember to import your modules in target files
// for typings to work
declare module 'overmind' {
  interface Config extends IConfig<typeof config> {}
}

export const appState = createOvermind(config, {devtools: true})
export const useAppState = createHook<typeof config>()
