import React, { useState, useEffect } from 'react'
import { useNavigation } from 'react-navi'
import { useTranslation } from 'react-i18next'

import { normalizeChains, normalizeLocationGroups } from '../../../config/utils'
import { useAppState } from '../../../state'
import { OrgLevelType, OrgLevel } from '../../../state/state'
import { openInNewTab } from '../../../config/utils'
import { UserLanguage } from '../../../state/rest'
import { getHighestRoleForUser } from '../Utils'
import { Menu } from '../../../sharedComponents/components'
import aromiLogo from '../../../Assets/Images/Aromi-logo.png'

const HOME_ROUTE_REGEX = new RegExp('^\\/(?:[^\\w]|$)')

export const NavigationMenu = () => {
  const [url, setUrl] = useState<string>()
  const { state, actions } = useAppState()
  const navigation = useNavigation()
  const { t } = useTranslation(['common', 'home'])

  const { orgLevel } = state
  const { href } = navigation.getCurrentValue().url
  const [activeRoute, setActiveRoute] = useState('/')
  const [aromiLink, setAromiLink] = useState<string>()

  useEffect(() => {
    const photo = state.me?.user?.photo
    if (photo && photo.assetMimeType && photo.assetMimeType.includes('image')) {
      try {
        actions.getDownloadUrl(state.me!.user.photo.id).then(d => setUrl(d.downloadURL))
      } catch (e) {
        console.error(e)
      }
    }
    if (state.selectedChainId!) {
      try {
        actions.getAromiLinkForChain(state.selectedChainId!).then(x => setAromiLink(x))
      } catch (err) {
        console.error(err)
      }
    }

  }, [state.me, actions])

  useEffect(() => {
    setActiveRoute(href)
  }, [href])

  const handleSiteSelect = (newSelection: OrgLevel) => {
    actions.updateOrgLevel({...newSelection})
  }

  const handleLanguageSelect = async (newLanguage: UserLanguage) => {
    try {
      await actions.updateAccountSettings({
        ...state.me!.user,
        language: newLanguage
      })
    } catch (err) {
      console.error(`Error updating user account settings, reason: ${err.message}`, err)
    }
  }

  const isAdminView = href === '/admin' || (href.includes('/edit/chain') && !Boolean(state.selectedChainId))
  const showSelectChain = state.me?.accessRights.superuser === true && !!state.selectedChainId
  const isSiteSelected = orgLevel?.type === OrgLevelType.SITE
  const canAccessCGIChannel = state.me?.user
    ? (getHighestRoleForUser(state.me?.user) !== 'BU' && getHighestRoleForUser(state.me?.user) !== 'HI') ||
    state.me?.accessRights.superuser
    : null

  const routes = [
    {
      title: t('common:menu.home', 'Home'),
      icon: 'home',
      onClick: () => {
        navigation.navigate('/')
        setActiveRoute('/')
      },
      isActive: HOME_ROUTE_REGEX.test(activeRoute)
    },
    ...(isSiteSelected
      ? [
        {
          title: t('common:menu.tasks', 'Tasks'),
          icon: 'tasks',
          onClick: () => {
            navigation.navigate('/tasks')
            setActiveRoute('/tasks')
          },
          isActive: activeRoute.startsWith('/tasks')
        }
      ]
      : []),
    ...(isSiteSelected
      ? [
        {
          title: t('common:menu.alarms', 'Alarms'),
          icon: 'alarms',
          onClick: () => {
            navigation.navigate('/alarms')
            setActiveRoute('/alarms')
          },
          isActive: activeRoute.startsWith('/alarms')
        }
      ]
      : []),
    ...(isSiteSelected
      ? [
        {
          title: t('common:menu.appliances', 'Appliances'),
          icon: 'fridge',
          onClick: () => {
            navigation.navigate('/appliances')
            setActiveRoute('/appliances')
          },
          isActive: activeRoute.startsWith('/appliances')
        }
      ]
      : []),
    {
      title: t('common:menu.documents', 'Documents'),
      icon: 'documents',
      onClick: () => {
        navigation.navigate('/documents')
        setActiveRoute('/documents')
      },
      isActive: activeRoute.startsWith('/documents')
    },
    {
      title: t('common:menu.instructions', 'Instructions'),
      icon: 'instructions',
      onClick: () => {
        navigation.navigate('/instructions')
        setActiveRoute('/instructions')
      },
      isActive: activeRoute.startsWith('/instructions')
    },
    /* {
      title: t('common:menu.academy', 'Academy'),
      icon: 'academy',
      onClick: () => {
        navigation.navigate('/academy')
        setActiveRoute('/academy')
      },
      isActive: activeRoute.startsWith('/academy')
    }, */
    {
      title: t('common:menu.dashboard', 'Dashboard'),
      icon: 'dashboard',
      onClick: () => {
        navigation.navigate('/dashboard')
        setActiveRoute('/dashboard')
      },
      isActive: activeRoute.startsWith('/dashboard')
    },
    {
      title: t('common:menu.settings', 'Settings'),
      icon: 'settings',
      onClick: () => {
        navigation.navigate('/settings')
        setActiveRoute('/settings')
      },
      isActive: activeRoute.startsWith('/settings')
    }
  ]

  const userOptions = [
    ...(!isAdminView
      ? [
        {
          icon: 'settings',
          description: t('home:actions.accountSettings', 'Account settings'),
          onClick: () => navigation.navigate('/settings/useraccount')
        }
      ]
      : []),
    ...(canAccessCGIChannel
      ? [
        {
          icon: 'info',
          description: t('common:supportChannelInfo', 'CGI Channel'),
          onClick: () => openInNewTab('https://cgichannel.fi.cgi.com/login/default'),
          isExternalLink: true
        }
      ]
      : []),
    ...(canAccessCGIChannel
      ? [
        {
          icon: 'support',
          description: t('common:customerServiceInfo', 'CGI Customer Portal'),
          onClick: () => openInNewTab('https://proactioneu.ent.cgi.com/jira/servicedesk/customer/portal/966'),
          isExternalLink: true
        }
      ]
      : []),
    ...(isSiteSelected
      ? [
        {
          icon: 'infoScreen',
          description: t('home:menu.infoScreenTasks', 'Info screen (Tasks and appliances)'),
          onClick: () => navigation.navigate('/info')
        }
      ]
      : []),
    ...(isSiteSelected
      ? [
        {
          icon: 'infoScreen',
          description: t('home:menu.infoScreenAppliances', 'Info screen (Appliances)'),
          onClick: () => navigation.navigate('/info/appliances')
        }
      ]
      : []),
    ...(showSelectChain
      ? [
        {
          icon: 'chain',
          description: t('home:actions.selectAnotherChain', 'Select another chain'),
          onClick: () => actions.resetChain()
        }
      ]
      : []),
    ...(!!state.selectedChainId && !!aromiLink ? [
      {
        imageUrl: aromiLogo,
        description: 'Aromi',
        onClick: () => openInNewTab(`${aromiLink}`),
        isExternalLink: true
      }
    ] : []),
    {
      icon: 'logout',
      description: t('home:actions.logout', 'Logout'),
      onClick: () => actions.logout()
    }
  ]

  const languageOptions = [
    {
      description: t('common:general.lang.english', 'English'),
      onClick: () => handleLanguageSelect(UserLanguage.en)
    },
    {
      description: t('common:general.lang.finnish', 'Finnish'),
      onClick: () => handleLanguageSelect(UserLanguage.fi)
    },
    {
      description: t('common:general.lang.swedish', 'Swedish'),
      onClick: () => handleLanguageSelect(UserLanguage.sv)
    },
    {
      description: t('common:general.lang.polish', 'Polish'),
      onClick: () => handleLanguageSelect(UserLanguage.pl)
    }
  ]

  const {email, firstName, lastName} = state.me!.user

  const language = {
    languageOptions,
    selectedLanguage: state.me!.user.language.toUpperCase()
  }

  const user = {
    email,
    firstName,
    lastName,
    photoUrl: url,
    userOptions
  }

  const getSiteSelect = () => ({
    chain: normalizeChains(state.chainsById[state.selectedChainId!]),
    locationGroups: normalizeLocationGroups(state.chainsById[state.selectedChainId!]),
    selectedSiteName: orgLevel!.type === OrgLevelType.CHAIN ? t('home:labels.allSites', 'All') : orgLevel!.name,
    chainSelectText: t('home:labels.all', 'All'),
    onClick: handleSiteSelect
  })

  return isAdminView ? (
    <Menu routes={[]} user={user} language={language} />
  ) : (
    <Menu routes={routes} siteSelect={getSiteSelect()} user={user} language={language} />
  )
}
