import React, {useState} from 'react'

import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent, RightSidebar} from '../../Layout/Layout'
import {InvisibleContainer, Baseline, Grid, GridSpan} from '../../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow} from '../../Molecules/ViewComponents/ViewHeader'
import {BigLabelInput, LabelInput, LabelTextArea} from '../../Atoms/Forms'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {Text} from '../../Atoms/Typography'
import SiteOrganisationList from '../../Organisms/SiteOrganisationList'
import {Helmet} from 'react-navi-helmet-async'
import {isEmpty} from 'lodash'

interface EditChainProps {
  id?: string
}
const EditChainView = ({id}: EditChainProps) => {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('locations')
  const chain = id ? state.chainsById[id] : undefined
  const isEditing = !!id
  const [error, setError] = useState('')
  const [name, setName] = useState(chain ? chain.name : '')
  const [description, setDescription] = useState(chain ? chain.description : '')
  const [wastePrice, setWastePrice] = useState(chain?.wastePrice ? chain.wastePrice : undefined)
  const [aromiLink, setAromiLink] = useState(chain?.aromiLink ? chain.aromiLink : '')

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isEditing) {
      try {
        await actions.updateChain({...chain!, name, description, wastePrice, aromiLink})
      } catch (error) {}
    } else {
      try {
        await actions.createChain({
          chain: {name, description, aromiLink},
          site: {
            name: 'some site',
            temperatureUnit: 'C',
            weightUnit: 'G',
            locale: 'fi-FI',
            timeZone: 'Europe/Helsinki'
          }
        })
      } catch (e) {}
    }

    nav.goBack()
  }

  const validateInput = (value: string) => {
    if (parseFloat(value) > 0) {
      const fixedValue: number = parseFloat(parseFloat(value).toFixed(2))
      setWastePrice(fixedValue)
      setError('')
    } else if (isEmpty(value)) {
      setWastePrice(0.01)
    } else if (parseFloat(value) <= 0) {
      setError(`${t('locations:errors.notNegative', 'Value cannot be a negative number or a zero')}`)
    } else {
      console.error('Invalid input, value must be a number')
      setError(`${t('locations:errors.mustBeNumber', 'Invalid input, value must be a number')}`)
    }
  }

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editChain', 'Edit chain')} />
      ) : (
        <Helmet title={t('common:routes.createChain', 'Create chain')} />
      )}
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={
              isEditing
                ? `${t('locations:labels.editChain', 'Edit chain')}`
                : `${t('locations:labels.newChain', 'New chain')}`
            }
            backLabel={t('locations:labels.locations', 'Location settings')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <form onSubmit={handleSave}>
              <Grid>
                <BigLabelInput
                  labelText={t('locations:labels.chainName', 'Chain name')}
                  placeholder={t('locations:placeholders.chainName', 'Chain name')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <span className="empty"></span>
                <LabelTextArea
                  labelText={t('locations:labels.description', 'Description')}
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                />
                <span className="empty"></span>
                <LabelInput
                  name="wastePrice"
                  labelText={t('common:general.price', 'Waste price EUR/KG')}
                  value={wastePrice}
                  defaultValue={3}
                  onChange={e => validateInput(e.target.value)}
                  errorMsg={error}
                  type="number"
                  step="0.01"
                />
                <span className="empty"></span>
                <LabelInput
                  name="aromiLink"
                  labelText={t('locations:labels.addAromiLink', 'Add aromi link')}
                  value={aromiLink}
                  onChange={e => setAromiLink(e.target.value)}
                />
                <GridSpan span={2}></GridSpan>
              </Grid>
              <ButtonRowWrap>
                <div className="empty"></div>
                <ButtonRowGrid>
                  <Button variant="secondary" negative onClick={() => nav.goBack()}>
                    {t('common:buttons.cancel', 'Cancel')}
                  </Button>
                  <Button variant="secondary" type="submit">
                    {t('common:buttons.save', 'Save')}
                  </Button>
                </ButtonRowGrid>
              </ButtonRowWrap>
            </form>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      {!!chain && (
        <RightSidebar>
          <InvisibleContainer style={{marginTop: '4rem'}}>
            <Baseline margin="1rem">
              <Text level={3}>{t('locations:labels.organisationHierarchy', 'Organisation hierarchy')}</Text>
              <div>
                <SiteOrganisationList data={chain} />
              </div>
            </Baseline>
          </InvisibleContainer>
        </RightSidebar>
      )}
    </MainLayoutWithoutStretch>
  )
}

export default EditChainView
