import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {EquipmentTypeKey, SensorMetadata, UserLanguage} from '../../state/rest'
import {
  Appliance,
  ApplianceStatus,
  CoolingCycle,
  MeasurementType,
  Sensor,
  SensorValue
} from '../../state/appliances/state'
import {colors} from '../../sharedComponents/colors'
import {assertUnreachable, convertUnit, localNumberFormat} from '../../config/utils'
import {NotificationType, Site, State} from '../../state/state'
import {
  getDatetimeAgo,
  getDuration,
  getHumanizedDateString,
  minutesToDurationString
} from '../../Components/Atoms/Utils'
import keyBy from 'lodash/keyBy'
import capitalize from 'lodash/capitalize'
import {
  checkLorawanAppliance,
  ERROR_NOTIFICATION_DURATION_MS,
  getApplianceData,
  getApplianceIcon,
  getIsCoolerAppliance,
  getSensorRoleTitle,
  hasApplianceCoolingLowAlarm,
  isApplianceCoolingInProgress
} from './appliancesUtils'
import {useAppState} from '../../state'
import {ResolveAlarmModal} from '../../modals/ResolveAlarmModal'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'
import {tempUnitStr} from '../../config/utils'
import {ApplianceCoolingProgress} from './ApplianceCoolingProgress'
import {v4 as uuid} from 'uuid'
import {SensorDataChart} from './SensorDataChart'
import {LoadMoreButton} from '../tasks/TodoTasks'
import tiny from 'tinycolor2'
import {Alarm, AlarmType} from '../../state/alarms/state'
import {
  alarmStatus,
  alarmTypeToTranslation,
  alarmUnit,
  alarmValueFormatter,
  dateValueFormatter,
  Units,
  alarmIconPicker,
  alarmSubtypeToTranslation,
  resolverNameFormatter
} from '../alarms/formatters'
import {
  ApplianceData,
  ApplianceDataCycleProgressType,
  ApplianceDetailsData,
  ColumnComponentProps,
  ColumnDef,
  DataField,
  EMPTY_VALUE_TEXT,
  IconPalette,
  SharedIcon,
  SmallIconPalette,
  Switch,
  Table,
  TabPane,
  Tabs,
  Text,
  Title
} from '../../sharedComponents/components'

export interface ApplianceDetailsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  showDetails: boolean
  appliance?: Appliance
  metadataExpanded: boolean
  selectedSensorId?: string
  onExpandButtonClick: React.MouseEventHandler<HTMLSpanElement>
  onSwitchChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ApplianceDetailsCard: React.FC<ApplianceDetailsCardProps> = ({showDetails, appliance, ...rest}) => {
  return (
    <ApplianceDetailsCardContainer showDetails={showDetails}>
      {showDetails && appliance && <DetailsCardContent appliance={appliance} {...rest} />}
    </ApplianceDetailsCardContainer>
  )
}

enum ApplianceDetailsCardTabKey {
  ALARMS = 'alarms',
  TEMPERATURE = 'temperature',
  RELATIVE_HUMIDITY = 'relative_humidity',
  CYCLES = 'cycles',
  STATUS = 'status'
}

interface DetailsCardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  appliance: Appliance
  metadataExpanded: boolean
  selectedSensorId?: string
  onExpandButtonClick: React.MouseEventHandler<HTMLSpanElement>
  onSwitchChange: React.ChangeEventHandler<HTMLInputElement>
}

const DetailsCardContent: React.FC<DetailsCardContentProps> = ({
  appliance,
  metadataExpanded,
  selectedSensorId,
  onExpandButtonClick,
  onSwitchChange,
  ...rest
}) => {
  const {state, actions} = useAppState()
  const {t, i18n} = useTranslation(['appliances', 'alarms', 'common'])
  const [selectedSensor, setSelectedSensor] = useState<Sensor>()
  const [selectedContent, setSelectedContent] = useState(ApplianceDetailsCardTabKey.ALARMS)
  const [isResolveAlarmModalOpen, setIsResolveAlarmModalOpen] = useState(false)
  const [selectedAlarmId, setSelectedAlarmId] = useState<string>()

  const options =
    appliance.sensors?.map(sensor => ({
      label: capitalize(getSensorRoleTitle(t, sensor.role)),
      value: sensor.id
    })) ?? []

  // Selected sensor is changed from outside the component
  useEffect(() => {
    const sensorId =
      selectedSensorId && options.map(option => option.value).includes(selectedSensorId)
        ? selectedSensorId
        : options[0]?.value
    const sensor = appliance.sensors?.find(sensor => sensor.id === sensorId)
    setSelectedSensor(sensor)
  }, [selectedSensorId])

  // Handle the change of selected sensor
  useEffect(() => {
    onContentTabChange(ApplianceDetailsCardTabKey.ALARMS)
    if (metadataExpanded) {
      loadMetadata()
    }
  }, [selectedSensor])

  useEffect(() => {
    if (metadataExpanded) {
      loadMetadata()
    }
  }, [metadataExpanded])

  const loadMetadata = async () => {
    try {
      if (!selectedSensor || !state.site?.id) {
        return
      }
      await actions.v1.appliances.getMetadataForSensor({sensorId: selectedSensor.id, siteId: state.site.id})
    } catch (err) {
      showDataLoadError()
    }
  }

  const loadAlarms = async () => {
    try {
      if (!selectedSensor || !state.site?.id) {
        return
      }
      await actions.v1.appliances.getAlarmsForSensor({
        applianceId: appliance.id,
        sensorId: selectedSensor.id,
        sensorRole: selectedSensor.role,
        siteId: state.site.id
      })
    } catch (err) {
      showDataLoadError()
    }
  }

  const loadCoolingCycles = async (offset?: number, reset?: boolean) => {
    try {
      await actions.v1.appliances.getCoolingCyclesForAppliance({applianceId: appliance.id, offset, reset})
    } catch (err) {
      showDataLoadError()
    }
  }

  const loadStatus = async () => {
    try {
      await actions.v1.appliances.getStatusForAppliance({applianceId: appliance.id})
    } catch (err) {
      showDataLoadError()
    }
  }

  const showDataLoadError = () => {
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: t('appliances:messages.dataLoadErrorTitle', 'Data load error'),
      description: t(
        'appliances:messages.dataLoadErrorDescription',
        'Loading data failed. Please try reloading the page.'
      ),
      visible: true,
      hideAfterDelay: ERROR_NOTIFICATION_DURATION_MS
    })
  }

  const onHeaderTabChange = (key: string) => {
    const sensor = appliance.sensors?.find(s => s.id === key)
    setSelectedSensor(sensor)
  }

  const onContentTabChange = (key: string) => {
    const tabKey = key as ApplianceDetailsCardTabKey
    setSelectedContent(tabKey)
    switch (tabKey) {
      case ApplianceDetailsCardTabKey.ALARMS:
        return loadAlarms()
      case ApplianceDetailsCardTabKey.TEMPERATURE:
        return
      case ApplianceDetailsCardTabKey.RELATIVE_HUMIDITY:
        return
      case ApplianceDetailsCardTabKey.CYCLES:
        return loadCoolingCycles(undefined, true)
      case ApplianceDetailsCardTabKey.STATUS:
        return loadStatus()
      default:
        assertUnreachable(tabKey)
        return
    }
  }

  const onAlarmRowClick = (alarm: Alarm) => {
    if (alarm.resolvedTime || !alarm.isResolvable) {
      return
    }
    setSelectedAlarmId(alarm.id)
    setIsResolveAlarmModalOpen(true)
  }

  const onCloseApplianceAlarmModal = () => {
    setSelectedAlarmId(undefined)
    setIsResolveAlarmModalOpen(false)
  }

  const onLoadMoreCoolingCycles = () => {
    loadCoolingCycles(state.v1.appliances.coolingCycles.data.coolingCycles?.length)
  }

  const phaseData = getApplianceData(t, appliance, state.site)
  const selectedPhaseData = phaseData.filter(data => data.sensorUuid === selectedSensor?.id)
  const selectedPhaseTemperatureData = selectedPhaseData[0]
  const selectedPhaseIdleTemperature =
    selectedPhaseTemperatureData && getIsCoolerAppliance(appliance) ? selectedPhaseTemperatureData.idleLimit : undefined
  let updatedAtText = undefined
  if (selectedPhaseTemperatureData) {
    const isCooling = isApplianceCoolingInProgress(appliance)
    if (appliance.type === EquipmentTypeKey.COOLER && isCooling) {
      selectedPhaseTemperatureData.value = (
        <ApplianceCoolingProgress
          appliance={appliance}
          cycleProgressType={ApplianceDataCycleProgressType.medium}
          hasAlarm={hasApplianceCoolingLowAlarm(appliance)}
        />
      )
      selectedPhaseTemperatureData.minValue = <span />
      selectedPhaseTemperatureData.maxValue = (
        <Text size="XS" color={colors.system.grey_50}>
          {t('appliances:labels.target', 'Target')}: {selectedPhaseTemperatureData.targetValue}
          {selectedPhaseTemperatureData.unit}
        </Text>
      )
    } else {
      const isLorawanAppliance = checkLorawanAppliance(appliance)
      selectedPhaseTemperatureData.value = (
        <ApplianceDataContainer>
          {selectedPhaseData.map(data => {
            const {
              value,
              unit,
              minValue,
              maxValue,
              targetValue,
              minUnit,
              maxUnit,
              hasAlarm,
              maxValueLabelText,
              minMaxValueDivider
            } = data
            const title = isLorawanAppliance ? data.title : undefined
            const showMax = getIsCoolerAppliance(appliance) ? targetValue : maxValue
            return (
              <ApplianceData
                key={data.title}
                value={value}
                unit={unit}
                minValue={minValue}
                maxValue={showMax}
                minUnit={minUnit}
                maxUnit={maxUnit}
                title={title}
                hasAlarm={hasAlarm}
                maxValueLabelText={maxValueLabelText}
                minMaxValueDivider={minMaxValueDivider}
              />
            )
          })}
        </ApplianceDataContainer>
      )
      selectedPhaseTemperatureData.minValue = <span />
      selectedPhaseTemperatureData.maxValue = <span />
    }
    if (selectedPhaseTemperatureData.updatedAt && !isCooling) {
      updatedAtText = t('appliances:labels.lastUpdate', 'Last update {{ago}}', {
        ago: getDatetimeAgo(new Date(selectedPhaseTemperatureData.updatedAt), i18n.language)
      })
    }
  }
  const {icon, title} = getIconAndTitle(t, appliance, selectedSensor)
  const sensorMetadata =
    state.v1.appliances.sensorMetadata.data.sensorId === selectedSensor?.id
      ? state.v1.appliances.sensorMetadata.data.sensorMetadata
      : undefined
  const metadata = getMetadata(t, i18n.language, selectedSensor, sensorMetadata, state, selectedPhaseIdleTemperature)
  const sensorAlarms =
    state.v1.appliances.sensorAlarms.data.sensorId === selectedSensor?.id
      ? state.v1.appliances.sensorAlarms.data.sensorAlarms
      : undefined
  const selectedPhaseActiveAlarmsCount = sensorAlarms?.filter(alarm => !alarm.resolvedTime).length ?? 0
  const alarmsTabTitle = ` ${t('appliances:labels.alarmsTab', 'Alarms')}${
    selectedPhaseActiveAlarmsCount > 0
      ? t('appliances:labels.alarmsTabActive', ' ({{active}} active)', {
          active: selectedPhaseActiveAlarmsCount
        })
      : ''
  }`
  const coolingCycles =
    state.v1.appliances.coolingCycles.data.applianceId === appliance.id
      ? state.v1.appliances.coolingCycles.data.coolingCycles
      : undefined
  const coolingCyclesCount =
    state.v1.appliances.coolingCycles.data.applianceId === appliance.id
      ? state.v1.appliances.coolingCycles.data.count
      : undefined
  const coolingCyclesLoading = state.v1.appliances.coolingCycles.loading
  const applianceStatuses =
    state.v1.appliances.applianceStatuses.data.applianceId === appliance.id
      ? state.v1.appliances.applianceStatuses.data.applianceStatuses
      : undefined
  const showTemperatureSensorDataChart =
    appliance.type === EquipmentTypeKey.COOLER ||
    appliance.type === EquipmentTypeKey.DISHWASHER ||
    appliance.type === EquipmentTypeKey.FREEZER ||
    appliance.type === EquipmentTypeKey.OVEN ||
    appliance.type === EquipmentTypeKey.STORAGE
  const showRelativeHumiditySensorDataChart =
    checkLorawanAppliance(appliance) &&
    (appliance.type === EquipmentTypeKey.FREEZER || appliance.type === EquipmentTypeKey.STORAGE)

  return (
    <DetailsCardContentContainer {...rest}>
      <ResolveAlarmModal
        isOpen={isResolveAlarmModalOpen}
        onClose={onCloseApplianceAlarmModal}
        onFormSubmit={async formData => {
          if (!selectedAlarmId || !state.site) {
            return
          }
          await actions.v1.appliances.resolveAlarm({
            alarmId: selectedAlarmId,
            resolution: formData.resolution,
            resolvedBy: formData.resolvedBy,
            siteId: state.site.id
          })
        }}
        onSaveDescription={async (description: string) => {
          if (!selectedAlarmId || !state.site) {
            return
          }
          await actions.v1.alarms.saveCommentForAlarm({
            alarmId: selectedAlarmId,
            comment: description,
            siteId: state.site?.id
          })
        }}
        alarmId={selectedAlarmId}
        title={t('appliances:labels.applianceAlarm', 'Appliance alarm')}
      />
      <DetailsCardHeader>
        <SharedIcon icon={getApplianceIcon(appliance.type)} width="2.875em" height="2.875em" />
        {options && selectedSensor && options.length > 1 ? (
          <DetailsCardHeaderTabs
            activeKey={selectedSensor.id}
            onChangeKey={onHeaderTabChange}
            tabBarExtraContent={{
              left: (
                <Title level={4} strong>
                  {appliance.name}
                </Title>
              ),
              right: (
                <SwitchContainer>
                  <Switch checked={!appliance.suspended} onChange={onSwitchChange}></Switch>
                </SwitchContainer>
              )
            }}
          >
            {options.map(option => (
              <TabPane key={option.value} tab={<Text size="L">{option.label}</Text>} tabKey={option.value} />
            ))}
          </DetailsCardHeaderTabs>
        ) : (
          <DetailsCardHeaderTitle>
            <Title level={4} strong>
              {appliance.name}
            </Title>
            <SwitchContainer>
              <Switch checked={!appliance.suspended} onChange={onSwitchChange}></Switch>
            </SwitchContainer>
          </DetailsCardHeaderTitle>
        )}
      </DetailsCardHeader>
      <ApplianceDetailsData
        {...selectedPhaseTemperatureData}
        title={title}
        titleIcon={icon}
        expanded={metadataExpanded}
        onExpandButtonClick={onExpandButtonClick}
        valueInfo={updatedAtText}
        metadata={metadata}
        hasTitleAlarm={selectedPhaseTemperatureData?.value === undefined || selectedPhaseTemperatureData.hasAlarm}
        compact
        applianceType={appliance.type}
      />
      <StyledTabs activeKey={selectedContent} onChangeKey={onContentTabChange}>
        <TabPane tab={<Text size="S">{alarmsTabTitle}</Text>} tabKey={ApplianceDetailsCardTabKey.ALARMS}>
          {sensorAlarms && (
            <StyledTable
              columnDefs={getAlarmsColumnDefs(t, state.site)}
              data={sensorAlarms}
              expandable={{expandedRowRender: item => getAlarmExpandedRow(t, state.site?.locale!, item)}}
              rowHoverBackgroundColor={colors.system.lightGrey_5}
              rowExpandedBackgroundColor={colors.system.lightGrey_5}
              onClickRow={onAlarmRowClick}
            />
          )}
        </TabPane>
        {showTemperatureSensorDataChart && (
          <TabPane
            tab={<Text size="S">{t('appliances:labels.temperatureTab', 'Temperature')}</Text>}
            tabKey={ApplianceDetailsCardTabKey.TEMPERATURE}
          >
            {selectedSensor && (
              <SensorDataChart
                appliance={appliance}
                sensor={selectedSensor}
                measurementType={MeasurementType.temperature}
              />
            )}
          </TabPane>
        )}
        {showRelativeHumiditySensorDataChart && (
          <TabPane
            tab={<Text size="S">{t('appliances:labels.relativeHumidityTab', 'Humidity (RH)')}</Text>}
            tabKey={ApplianceDetailsCardTabKey.RELATIVE_HUMIDITY}
          >
            {selectedSensor && (
              <SensorDataChart
                appliance={appliance}
                sensor={selectedSensor}
                measurementType={MeasurementType.relativeHumidity}
              />
            )}
          </TabPane>
        )}
        {appliance.type === EquipmentTypeKey.COOLER && (
          <TabPane
            tab={<Text size="S">{t('appliances:labels.completedCyclesTab', 'Completed cycles')}</Text>}
            tabKey={ApplianceDetailsCardTabKey.CYCLES}
          >
            {coolingCycles && typeof coolingCyclesCount === 'number' && (
              <StyledTable
                columnDefs={getCoolingCyclesColumnDefs(t, state.site)}
                data={coolingCycles}
                expandable={{expandedRowRender: item => getCoolingCycleExpandedRow(t, i18n.language, item, state)}}
                rowHoverBackgroundColor={colors.system.lightGrey_5}
                rowExpandedBackgroundColor={colors.system.lightGrey_5}
                lastRow={
                  <LastRow
                    onLoadMore={onLoadMoreCoolingCycles}
                    items={coolingCycles}
                    totalItemCount={coolingCyclesCount}
                    loading={coolingCyclesLoading}
                  />
                }
              />
            )}
          </TabPane>
        )}
        <TabPane
          tab={<Text size="S">{t('appliances:labels.applianceStatusList', 'Appliance status')}</Text>}
          tabKey={ApplianceDetailsCardTabKey.STATUS}
        >
          {applianceStatuses && (
            <StyledTable
              columnDefs={getStatusColumnDefs(t, i18n.language, state.site)}
              data={applianceStatuses}
              expandable={{expandedRowRender: item => getApplianceStatusExpandedRow(t, i18n.language, item, state)}}
              rowHoverBackgroundColor={colors.system.lightGrey_5}
              rowExpandedBackgroundColor={colors.system.lightGrey_5}
            />
          )}
        </TabPane>
      </StyledTabs>
    </DetailsCardContentContainer>
  )
}

const LastRowContainer = styled.div<{loadMore: boolean}>(
  ({loadMore}) => `
  padding: ${loadMore ? 0.5 : 1.5}rem 0;
`
)

const ApplianceDataContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
`

interface LastRowProps {
  onLoadMore: React.MouseEventHandler<HTMLButtonElement>
  items: unknown[]
  totalItemCount: number
  loading?: boolean
}

const LastRow: React.FC<LastRowProps> = ({onLoadMore, items, totalItemCount, loading}) => {
  const {t} = useTranslation('common')
  const loadMore = totalItemCount > items.length
  let content = null
  if (loading) {
    content = <Text size="S">{t('common:labels.loading', 'Loading...')}</Text>
  } else if (loadMore) {
    content = <LoadMoreButton onClick={onLoadMore} />
  } else {
    content = <Text size="S">{t('common:labels.noMoreLoading', 'No more to load')}</Text>
  }

  return <LastRowContainer loadMore={loadMore}>{content}</LastRowContainer>
}

const getIconAndTitle = (
  t: TFunction,
  appliance: Appliance,
  sensor?: Sensor
): {
  icon?: IconPalette | SmallIconPalette
  title?: string
} => {
  const temperatureMeasurementType = sensor?.measurementTypes?.temperature
  let icon: IconPalette | SmallIconPalette | undefined = temperatureMeasurementType?.hasActiveAlarm
    ? IconPalette.Alarm
    : SmallIconPalette.Check
  let title: string | undefined = undefined
  if (
    temperatureMeasurementType?.latestValue?.value === undefined ||
    temperatureMeasurementType?.latestValue?.value === null
  ) {
    icon = IconPalette.Alarm
    title = t('appliances:labels.noSensorData', 'Receiving no data from sensor')
  } else if (appliance.type === EquipmentTypeKey.DISHWASHER) {
    icon = undefined
    title = undefined
  } else if (appliance.type === EquipmentTypeKey.COOLER) {
    if (hasApplianceCoolingLowAlarm(appliance)) {
      title = t(
        'appliances:labels.coolingLowStartTemperature',
        'Starting temperature was too low. This has triggered an alarm'
      )
    } else if (isApplianceCoolingInProgress(appliance)) {
      icon = SmallIconPalette.LateTask
      title = t('appliances:labels.coolingInProgress', 'Cooling in progress')
    } else {
      icon = undefined
      title = undefined
    }
  } else {
    if (
      typeof temperatureMeasurementType?.latestValue?.value === 'number' &&
      typeof temperatureMeasurementType?.alarmRule?.minValue === 'number' &&
      temperatureMeasurementType.latestValue.value < temperatureMeasurementType?.alarmRule?.minValue
    ) {
      title = t('appliances:labels.currentTemperatureTooLow', 'Current temperature is too low')
    } else if (
      typeof temperatureMeasurementType?.latestValue?.value === 'number' &&
      typeof temperatureMeasurementType?.alarmRule?.maxValue === 'number' &&
      temperatureMeasurementType.latestValue.value > temperatureMeasurementType?.alarmRule?.maxValue
    ) {
      title = t('appliances:labels.currentTemperatureTooHigh', 'Current temperature is too high')
    } else {
      title = t('appliances:labels.currentTemperatureWithinTarget', 'Current temperature is within target')
    }
  }
  return {icon, title}
}

/**
 * Gets sensor latest value of the measurementType with newest timestamp
 * @param sensor
 * @returns
 */
const getSensorLatestValue = (sensor: Sensor): SensorValue | null => {
  const keys = Object.keys(sensor.measurementTypes ?? {}) as MeasurementType[]
  const latestValues = keys.map(key => sensor.measurementTypes?.[key]?.latestValue)
  if (latestValues.length === 0) {
    return null
  }
  return latestValues.reduce((res, curr) => {
    if (!res?.timestamp) {
      return curr
    }
    if (!curr?.timestamp) {
      return res
    }
    return res.timestamp > curr.timestamp ? res : curr
  }, latestValues[0]) as SensorValue
}

const getMetadata = (
  t: TFunction,
  language: string,
  sensor?: Sensor,
  sensorMetadata?: SensorMetadata[],
  state?: Pick<State, 'site' | 'me'>,
  idleTemperatureLimit?: number
) => {
  if (!sensor || !sensorMetadata || !state?.site) {
    return [
      {label: t('appliances:labels.sensorId', 'Sensor ID'), value: undefined},
      {label: t('appliances:labels.signalStrength', 'Signal strength'), value: undefined},
      {label: t('appliances:labels.sensorModel', 'Sensor model'), value: undefined},
      {label: t('appliances:labels.calibrationDate', 'Calibration date'), value: undefined},
      {label: t('appliances:labels.gatewayId', 'Gateway ID'), value: undefined},
      {label: t('appliances:labels.mainBatteryLevel', 'Battery level'), value: undefined},
      {label: t('appliances:labels.firmwareVersion', 'Firmware version'), value: undefined},
      {label: t('appliances:labels.serialNumber', 'Serial number'), value: undefined}
    ]
  }
  const metadataMap = keyBy(sensorMetadata, 'key')
  const sensorId = sensor.context?.sensorNumber || sensor.context?.devEui
  const latestValue = getSensorLatestValue(sensor)
  const signalStrengthValue = metadataMap['signal_strength']?.value ?? latestValue?.signalMetadata?.rssi
  const signalStrengthTimestamp = metadataMap['signal_strength']?.timestamp ?? latestValue?.timestamp
  const signalStrength =
    signalStrengthValue !== undefined && signalStrengthValue !== null && signalStrengthTimestamp
      ? `${localNumberFormat(state.site.locale, parseFloat(signalStrengthValue))} dBm (${getDatetimeAgo(
          new Date(signalStrengthTimestamp),
          language
        )})`
      : null
  const sensorModel = metadataMap['device_type']?.value
  const calibrationDate =
    metadataMap['calibration_date']?.value &&
    getHumanizedDateString(new Date(metadataMap['calibration_date']?.value), state.site.locale)
  const gatewayId = sensor.context?.gatewayNumber
  const batteryLevelValue =
    metadataMap['main_battery_level']?.value ?? sensor.measurementTypes?.batteryLevel?.latestValue?.value
  const batteryLevel = batteryLevelValue !== undefined && batteryLevelValue !== null ? `${batteryLevelValue}%` : null
  const firmwareVersion = metadataMap['firmware_version']?.value
  const serialNumber = metadataMap['serial_number']?.value
  const idleLimit = idleTemperatureLimit
    ? `${localNumberFormat(state.site.locale, convertUnit(state.site, idleTemperatureLimit))} ${tempUnitStr(
        state.site.temperatureUnit
      )}`
    : null
  const labels = [
    {label: t('appliances:labels.sensorId', 'Sensor ID'), value: sensorId},
    {label: t('appliances:labels.signalStrength', 'Signal strength'), value: signalStrength},
    {label: t('appliances:labels.sensorModel', 'Sensor model'), value: sensorModel},
    {
      label: t('appliances:labels.calibrationDate', 'Calibration date'),
      value:
        calibrationDate && serialNumber ? (
          <CalibrationDate
            calibrationDate={calibrationDate}
            serialNumber={serialNumber}
            language={state.me?.user.language}
          />
        ) : (
          calibrationDate
        )
    },
    {label: t('appliances:labels.gatewayId', 'Gateway ID'), value: gatewayId},
    {label: t('appliances:labels.mainBatteryLevel', 'Battery level'), value: batteryLevel},
    {label: t('appliances:labels.firmwareVersion', 'Firmware version'), value: firmwareVersion},
    {label: t('appliances:labels.serialNumber', 'Serial number'), value: serialNumber}
  ]
  return idleLimit
    ? labels.concat({label: t('appliances:labels.cycleStartLimit', 'Cycle start limit'), value: idleLimit})
    : labels
}

const ApplianceDetailsCardContainer = styled.div<{showDetails?: boolean}>(
  ({showDetails}) => css`
    box-shadow: 0px 0px 1rem
      ${tiny('#D1D1D6')
        .setAlpha(0.5)
        .toRgbString()};
    border-radius: 1rem;
    background-color: ${colors.system.white};
    flex-basis: ${showDetails ? '50%' : '0'};
    transition: flex-basis 0.3s ease-out, width 0.3s ease-out, visibility 0.3s ease-out, min-width 0.3s ease-out;
    ${showDetails && 'margin-right: 1rem;'}
    height: calc(100vh - 11.625rem);
    ${!showDetails && 'width: 0;'}
    ${!showDetails && 'visibility: hidden;'}
    overflow-y: auto;
    overflow-x: hidden;
    min-width: ${showDetails ? '49rem' : '0'};
  `
)

const DetailsCardContentContainer = styled.div`
  min-width: 49rem;
  padding: 1.5rem;
  display: grid;
  grid-template: auto auto 1fr / auto;
  gap: 0.5rem;
`

const DetailsCardHeader = styled.div`
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 1.5rem;
`

const DetailsCardHeaderTabs = styled(Tabs)`
  header {
    display: grid;
    grid-template: auto / auto auto 1fr;
    gap: 2rem;
    align-items: end;
  }

  ul.tabs li.tab a {
    padding: 0.5rem 1rem;

    span {
      font-size: 1.06rem;
      line-height: 1.375rem;
    }
  }

  div.extraContent {
    display: block;
    padding: 0 0 0.5rem 0;
    border: none;
  }
`

const DetailsCardHeaderTitle = styled.div`
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 2rem;
`

const SwitchContainer = styled.div`
  display: grid;
  justify-content: end;
`

const StyledTabs = styled(Tabs)`
  ul.tabs li.tab a {
    padding: 0.5rem 1rem;
  }
`

const StyledTable = styled(Table)`
  thead th {
    height: auto;
    border: none;
    padding-top: 0.75rem;
    font-size: 0.75rem;
    line-height: 1rem;

    :first-child {
      padding-left: 1rem;
    }

    :last-child {
      padding-right: 1rem;
    }
  }

  tbody tr {
    height: 3.5rem;
    border-bottom: none;

    :first-child {
      border-top: none;
    }

    :last-child {
      border-bottom: none;
    }

    td:first-child {
      padding-left: 1rem;
    }

    td:last-child {
      padding-right: 1rem;
    }

    td[colspan] {
      padding: 0;

      :not(:last-child) {
        border-bottom: 1px solid ${colors.system.grey_5};
      }
    }
  }
`

const AlarmExpandedRow = styled.div`
  display: grid;
  grid-template: auto auto / ${(20 / 84) * 100}% ${(25 / 84) * 100}% 1fr;
  row-gap: 1rem;
  padding: 1rem 8% 1rem 8%;
  border-top: 1px solid ${colors.system.grey_5};
`

const StatusExpandedRow = styled(AlarmExpandedRow)`
  grid-template: auto auto / ${(20 / 84) * 100}% ${(30 / 84) * 100}% 1fr;
  grid-template-areas:
    '. . reason'
    '. . reason';
`

const CoolingCycleExpandedRow = styled(AlarmExpandedRow)`
  grid-template: auto auto / ${(20 / 84) * 100}% ${(20 / 84) * 100}% 1fr;
  grid-template-areas: none;
`

const StatusReasonDataField = styled(DataField)`
  grid-area: reason;
  align-content: start;
`

const CalibrationDate: React.FC<{calibrationDate?: string; serialNumber: string; language?: string}> = ({
  calibrationDate,
  serialNumber,
  language
}) => {
  const nokevalLanguage = () => {
    switch (language) {
      case UserLanguage.fi:
        return 'Finnish'
      // There is no Swedish so use English instead
      default:
        return 'English'
    }
  }

  const url = `http://ws08.nokeval.com/cc.php?calibration&certificates&submit&language=${nokevalLanguage()}&serial=${serialNumber}`

  return (
    <CalibrationDateContainer target="_blank" href={url} rel="noopener">
      <Text size="M" color={colors.system.blue}>
        {calibrationDate}
      </Text>
      <SharedIcon icon={SmallIconPalette.ExternalLink} width="0.75em" height="0.75em" fill={colors.system.blue} />
    </CalibrationDateContainer>
  )
}

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const CalibrationDateContainer = styled(Link)`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  text-decoration: none;
`

const getAlarmsColumnDefs = (t: TFunction, site?: Site): ColumnDef[] => {
  if (!site) {
    return []
  }
  const units: Units = {
    temperature: site.temperatureUnit,
    weight: site.weightUnit
  }
  return [
    {
      title: '',
      dataKeys: ['type', 'resolvedTime'],
      widthPercentage: '8%',
      component: (props: ColumnComponentProps) => {
        const type = props.values[0]
        const resolved = Boolean(props.values[1])
        const color = resolved ? colors.brand.cobalt : colors.system.red

        return <SharedIcon icon={alarmIconPicker(type)} height="2em" width="2em" fill={color} />
      }
    },
    {
      title: t('alarms:labels.tableHeading.subtype', 'Alarm condition'),
      dataKeys: ['type', 'subtype', 'resolvedTime'],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const type = props.values[0]
        const subtype = props.values[1]
        const tableSubtype = subtype ?? undefined
        const resolved = Boolean(props.values[2])
        const color = resolved ? colors.brand.cobalt : colors.system.red
        const strong = props.selected
        // Use alarm type translation as fallback
        const text = alarmSubtypeToTranslation(t, type, tableSubtype) || alarmTypeToTranslation(t, type)

        return (
          <Text size="S" strong={strong} color={color}>
            {text}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.triggeredTime', 'Triggered time'),
      dataKeys: ['triggeredTime', 'resolvedTime'],
      widthPercentage: '25%',
      component: (props: ColumnComponentProps) => {
        const resolved = Boolean(props.values[1])
        const color = resolved ? colors.brand.cobalt : colors.system.red
        const strong = props.selected
        const triggeredTime = props.values[0]

        return (
          <Text size="S" color={color} strong={strong}>
            {dateValueFormatter(triggeredTime, site.locale)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.measuredValue', 'Measured value'),
      dataKeys: ['measuredValue', 'type', 'subtype', 'unit', 'resolvedTime'],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const measuredValue = props.values[0]
        const type = props.values[1]
        const subtype = props.values[2]
        const unit = props.values[3]
        const resolved = Boolean(props.values[4])
        const color = resolved ? colors.brand.cobalt : colors.system.red
        const strong = props.selected

        return (
          <Text size="S" color={color} strong={strong}>
            {alarmValueFormatter(measuredValue, type, subtype, site)}
            {alarmUnit(t, type, subtype, unit, units)}
          </Text>
        )
      }
    },
    {
      title: '',
      dataKeys: ['resolvedBy', 'actor', 'type', 'isResolvable', 'resolvedTime'],
      widthPercentage: '22%',
      component: (props: ColumnComponentProps) => {
        const resolvedBy = props.values[0]
        const actor = props.values[1]
        const type = props.values[2] as AlarmType
        const isResolvable = Boolean(props.values[3])
        const resolved = Boolean(props.values[4])
        const text = resolved
          ? resolvedBy || resolverNameFormatter(actor)
          : getAlarmUnresolvableDescription(t, type, isResolvable)
        const color = resolved ? colors.brand.cobalt : colors.system.red
        const strong = props.selected

        return (
          <Text size="S" color={color} strong={strong}>
            {text}
          </Text>
        )
      }
    }
  ]
}

const getAlarmUnresolvableDescription = (t: TFunction, type: AlarmType, isResolvable: boolean) => {
  if (isResolvable) {
    return undefined
  }
  switch (type) {
    case AlarmType.Disconnect:
      return t('appliances:labels.waitingSensorReconnect', 'Waiting for the sensor to reconnect.')
    case AlarmType.Temperature:
      return t(
        'appliances:labels.waitingTemperatureInRange',
        'Waiting for the temperature to return to the target range.'
      )
    case AlarmType.Humidity:
      return t('appliances:labels.waitingHumidityInRange', 'Waiting for the humidity to return to the target range.')
    default:
      return undefined
  }
}

const getStatusColumnDefs = (t: TFunction, language: string, site?: Site): ColumnDef[] => {
  if (!site) {
    return []
  }
  return [
    {
      title: '',
      dataKeys: [],
      widthPercentage: '8%',
      component: () => {
        return <SharedIcon icon={IconPalette.Power} width="2em" height="2em" fill={colors.brand.cobalt} />
      }
    },
    {
      title: t('appliances:labels.status', 'Status'),
      dataKeys: [],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {t<string>('appliances:labels.statusOff', 'Off')}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.timePeriod', 'Time period'),
      dataKeys: ['startDate', 'endDate'],
      widthPercentage: '30%',
      component: (props: ColumnComponentProps) => {
        const startDatetime = getHumanizedDateString(new Date(props.values[0]), site.locale)
        const endDatetime = props.values[1] ? getHumanizedDateString(new Date(props.values[1]), site.locale) : ''
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {startDatetime} → {endDatetime}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.duration', 'Duration'),
      dataKeys: ['startDate', 'endDate'],
      widthPercentage: '15%',
      component: (props: ColumnComponentProps) => {
        const duration = props.values[1]
          ? getDuration(new Date(props.values[0]), new Date(props.values[1]), language)
          : EMPTY_VALUE_TEXT
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {duration}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.changedBy', 'Changed by'),
      dataKeys: ['updatedBy'],
      widthPercentage: '22%',
      component: (props: ColumnComponentProps) => {
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {props.values[0]}
          </Text>
        )
      }
    }
  ]
}

const getAlarmExpandedRow = (t: TFunction, locale: string, alarm: Alarm) => {
  if (!alarm.resolvedTime) {
    return null
  }
  const type = alarmTypeToTranslation(t, alarm.type)
  const status = alarmStatus(t, alarm.status, alarm.isResolvable, alarm.type)
  const triggeredTime = dateValueFormatter(alarm.triggeredTime, locale)
  const duration = alarm.duration ? minutesToDurationString(alarm.duration, true) : EMPTY_VALUE_TEXT
  const resolvedTime = dateValueFormatter(alarm.resolvedTime, locale)

  return (
    <AlarmExpandedRow>
      <DataField valueSize="S" labelSize="XS" label={t('alarms:labels.tableHeading.type', 'Alarm type')} value={type} />
      <DataField
        valueSize="S"
        labelSize="XS"
        label={t('alarms:labels.tableHeading.triggeredTime', 'Triggered time')}
        value={triggeredTime}
      />
      <DataField valueSize="S" labelSize="XS" label={t('alarms:label.details.reason', 'Reason')} value={alarm.reason} />
      <DataField valueSize="S" labelSize="XS" label={t('alarms:label.details.duration', 'Duration')} value={duration} />
      <DataField
        valueSize="S"
        labelSize="XS"
        label={t('alarms:label.details.resolvedTime', 'Resolved time')}
        value={resolvedTime}
      />
      <DataField valueSize="S" labelSize="XS" label={t('alarms:labels.tableHeading.status', 'Status')} value={status} />
    </AlarmExpandedRow>
  )
}

const getApplianceStatusExpandedRow = (
  t: TFunction,
  language: string,
  item: ApplianceStatus,
  state: Pick<State, 'site'>
) => {
  const fromTime = item.startDate && dateValueFormatter(item.startDate, state.site?.locale!)
  const duration = getDuration(new Date(item.startDate), item.endDate ? new Date(item.endDate) : new Date(), language)
  const toTime = item.endDate && dateValueFormatter(item.endDate, state.site?.locale!)

  return (
    <StatusExpandedRow>
      <DataField valueSize="S" labelSize="XS" label={t('appliances:labels.duration', 'Duration')} value={duration} />
      <DataField valueSize="S" labelSize="XS" label={t('appliances:labels.from', 'From')} value={fromTime} />
      <StatusReasonDataField
        valueSize="S"
        labelSize="XS"
        label={t('appliances:labels.reason', 'Reason')}
        value={item.reason}
      />
      <span />
      <DataField valueSize="S" labelSize="XS" label={t('appliances:labels.to', 'To')} value={toTime} />
    </StatusExpandedRow>
  )
}

const getCoolingCyclesColumnDefs = (t: TFunction, site?: Site): ColumnDef[] => {
  if (!site) {
    return []
  }
  const temperatureUnit = tempUnitStr(site.temperatureUnit)

  return [
    {
      title: '',
      dataKeys: ['alarms', 'event'],
      widthPercentage: '8%',
      component: (props: ColumnComponentProps) => {
        const completedSuccesfully = props.values[0].length === 0
        const coolingTargetMissing = !props.values[1]?.suggestions[0] || props.values[1]?.suggestions[0].length === 0
        const iconType =
          completedSuccesfully && !coolingTargetMissing ? IconPalette.CompletedCycle : IconPalette.AlertTriangle
        return <SharedIcon icon={iconType} width="2em" height="2em" fill={colors.brand.cobalt} />
      }
    },
    {
      title: t('appliances:labels.status', 'Status'),
      dataKeys: ['alarms', 'event'],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const completedSuccesfully = props.values[0].length === 0
        const coolingTargetMissing = !props.values[1]?.suggestions[0] || props.values[1]?.suggestions[0].length === 0
        let status
        if (!completedSuccesfully) {
          status = t('appliances:labels.failed', 'Failed')
        } else if (coolingTargetMissing) {
          status = t('appliances:labels.missingInformation', 'Missing information')
        } else {
          status = t('appliances:labels.completed', 'Completed')
        }
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {status}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.completedTime', 'Completed time'),
      dataKeys: ['endedAt'],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const completedTimeText = props.values[0] ? dateValueFormatter(props.values[0], site.locale) : EMPTY_VALUE_TEXT
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {completedTimeText}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.measuredValues', 'Measured values'),
      dataKeys: ['startLimit', 'targetLimit', 'firstMeasurement', 'lastMeasurement'],
      widthPercentage: '20%',
      component: (props: ColumnComponentProps) => {
        const startLimit = props.values[0] as number
        const targetLimit = props.values[1] as number
        const firstMeasurement = props.values[2] as number
        const lastMeasurement = props.values[3] as number
        const start =
          firstMeasurement < startLimit
            ? localNumberFormat(site.locale, convertUnit(site, firstMeasurement))
            : localNumberFormat(site.locale, convertUnit(site, startLimit))
        const end =
          lastMeasurement > targetLimit
            ? localNumberFormat(site.locale, convertUnit(site, lastMeasurement))
            : localNumberFormat(site.locale, convertUnit(site, targetLimit))
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {start}
            {temperatureUnit} → {end}
            {temperatureUnit}
          </Text>
        )
      }
    },
    {
      title: t('appliances:labels.completedBy', 'Completed by'),
      dataKeys: ['event'],
      widthPercentage: '27%',
      component: (props: ColumnComponentProps) => {
        const completedByText = props.values[0]?.actorName || EMPTY_VALUE_TEXT
        const strong = props.selected

        return (
          <Text size="S" strong={strong}>
            {completedByText}
          </Text>
        )
      }
    }
  ]
}

const FillInCoolingTargetLink: React.FC<{strong?: boolean}> = ({strong}) => {
  const {t} = useTranslation()

  return (
    <Link href="/tasks" onClick={event => event.stopPropagation()}>
      <Text size="S" strong={strong} color={colors.system.blue}>
        {t('appliances:labels.fillInCoolingTargetLink', 'Fill in cooling target')}
      </Text>
    </Link>
  )
}

const getCoolingCycleExpandedRow = (t: TFunction, language: string, item: CoolingCycle, state: Pick<State, 'site'>) => {
  if (!state.site) {
    return null
  }

  const coolingTarget = item.event?.suggestions[0] ?? <FillInCoolingTargetLink strong />
  const targetText = t('appliances:labels.target', 'Target')
  const temperatureUnit = tempUnitStr(state.site.temperatureUnit)
  const peakValue = localNumberFormat(state.site.locale, convertUnit(state.site, item.firstMeasurement as number))
  const peakValueElement = (
    <Text size="S" color={colors.brand.cobalt}>
      {peakValue}
      {temperatureUnit}
    </Text>
  )
  const startValueFailed = item.firstMeasurement < item.startLimit
  const startValue = startValueFailed
    ? peakValue
    : localNumberFormat(state.site.locale, convertUnit(state.site, item.startLimit as number))
  const startValueElement = (
    <Text size="S" color={startValueFailed ? colors.system.red : colors.brand.cobalt}>
      {startValue}
      {temperatureUnit} ({targetText}: Min {item.startLimit}
      {temperatureUnit})
    </Text>
  )
  const endValue = localNumberFormat(state.site.locale, convertUnit(state.site, item.lastMeasurement as number))
  const endValueFailed = item.lastMeasurement > item.targetLimit
  const endValueElement = (
    <Text size="S" color={endValueFailed ? colors.system.red : colors.brand.cobalt}>
      {endValue}
      {temperatureUnit} ({targetText}: Max {item.targetLimit}
      {temperatureUnit})
    </Text>
  )
  const duration = getDuration(new Date(item.startedAt), new Date(item.endedAt), language)
  const startTime = item.startedAt ? dateValueFormatter(item.startedAt, state.site.locale) : EMPTY_VALUE_TEXT
  const endTime = item.endedAt ? dateValueFormatter(item.endedAt, state.site.locale) : EMPTY_VALUE_TEXT

  return (
    <CoolingCycleExpandedRow>
      <DataField
        valueSize="S"
        labelSize="XS"
        label={t('appliances:labels.coolingTarget', 'Cooling target')}
        value={coolingTarget}
      />
      <DataField
        valueSize="S"
        labelSize="XS"
        label={t('appliances:labels.startTime', 'Start time')}
        value={startTime}
      />
      <DataField
        label={t('appliances:labels.startingValue', 'Start temperature')}
        labelSize="XS"
        value={startValueElement}
      />
      <DataField valueSize="S" labelSize="XS" label={t('appliances:labels.duration', 'Duration')} value={duration} />
      <DataField
        valueSize="S"
        labelSize="XS"
        label={t('appliances:labels.completedTime', 'Completed time')}
        value={endTime}
      />
      <DataField label={t('appliances:labels.endingValue', 'End temperature')} labelSize="XS" value={endValueElement} />
      <DataField label={t('appliances:labels.peakValue', 'Peak temperature')} labelSize="XS" value={peakValueElement} />
    </CoolingCycleExpandedRow>
  )
}
