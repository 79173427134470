import React, {useState} from 'react'
import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {Baseline, InvisibleContainer} from '../../../Layout/Grid'
import SiteSelectionCheckboxStyle from '../../../../Components/Organisms/SiteSelectionCheckboxStyle'
import {HeaderRow, GoBackHeaderTitle} from '../../../Molecules/ViewComponents/ViewHeader'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../../state'
import {useNavigation} from 'react-navi'
import {Text, DatePicker, Input, Button, SwitchReversed} from '../../../../sharedComponents/components'
import {colors} from '../../../../sharedComponents/colors'
import {MultipleSiteSuspendedPeriodPayload} from '../../../../state/settings/multipleSiteSuspendedPeriod/state'

const MultipleSiteClosingPeriodPage = () => {
  type DatePicker = {
    startDate: Date
    endDate: Date
  }
  const getDefaultFormState = () => {
    const now = new Date()
    const dayFromNow = new Date(now.setDate(now.getDate() + 1))
    return {
      defaultForm: {
        reason: '',
        startAt: new Date().toISOString(),
        endAt: dayFromNow.toISOString(),
        alarmsEnabled: false,
        sites: []
      },
      startDate: new Date(),
      endDate: dayFromNow
    }
  }

  const formState = getDefaultFormState()
  const {state, actions} = useAppState()
  const nav = useNavigation()
  const locale = state.site!.locale || 'fi-FI'
  const {t} = useTranslation('settings')
  const [formData, setFormData] = useState<MultipleSiteSuspendedPeriodPayload>(formState.defaultForm)
  const [datePicker, setDatePicker] = useState<DatePicker>({startDate: formState.startDate, endDate: formState.endDate})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const areDatesSelected = datePicker.startDate && datePicker.endDate
  const closingDates: Date[][] = [] // Define closingDates as an empty array or fetch the actual closing dates
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [isChainWide, setIsChainWide] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedSites, setSelectedSites] = useState<string[]>([])
  const chain = state.chainsById[state.selectedChainId!]
  let writableIds = state.me?.accessRights?.read.sites
  writableIds = writableIds?.concat(...(state.me?.accessRights?.write.chains ?? []))

  const noOverlapMessage = t(
    'settings:description.noOverlapMessage',
    'You cannot add a closing period that overlaps with existing periods. Pick new dates or remove an existing period.'
  )
  const inValidDatesMessage = t(
    'settings:description.inValidDatesMessage',
    'Starting date for the suspended period must be before the ending date. Select new dates for this period.'
  )

  const switchAlarmsEnabled = () => {
    const alarmsEnabled = formData.alarmsEnabled
    setFormData({...formData, alarmsEnabled: !alarmsEnabled})
  }

  const changeReason = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({...formData, reason: event.target.value})
  }

  const areOverlappingPeriods = (existingPeriods: Date[][], selectedDates: DatePicker) => {
    const periodsOverlap = (existing: Date[], selected: DatePicker) => {
      return selected.endDate >= existing[0] && selected.startDate <= existing[1]
    }

    for (const period of existingPeriods) {
      if (periodsOverlap(period, selectedDates)) {
        return true
      }
    }
    return false
  }

  const onDateChange = (name: string, value: Date) => {
    setDatePicker({...datePicker, [name]: value})
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const start = datePicker.startDate
    const end = datePicker.endDate

    // set start to 00:00:00 and end to 23:59:59:999 to make sure that the whole day is included
    start.setHours(0, 0, 0, 0)
    end.setHours(23, 59, 59, 999)

    const overlappingPeriods =
      closingDates.length === 0 || !areDatesSelected ? false : areOverlappingPeriods(closingDates, datePicker)

    if (overlappingPeriods) {
      setErrorMessage(noOverlapMessage)
    } else if (start >= end) {
      setErrorMessage(inValidDatesMessage)
    } else {
      setSubmitting(true)
      const payload = {
        ...formData,
        alarmsEnabled: !formData.alarmsEnabled, // when toggle is checked it has value of true,
        startAt: start.toISOString(),
        endAt: end.toISOString(),
        sites: selectedSites
      }

      await actions.v1.settings.multipleSiteSuspendedPeriod.newMultipleSiteSuspendedPeriod(payload)
      resetForm()
      setSubmitting(false)
      setErrorMessage(null)
      nav.navigate(`/settings/locations/`)
    }
  }

  const resetForm = () => {
    setFormData({
      reason: '',
      startAt: new Date().toISOString(),
      endAt: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
      alarmsEnabled: false,
      sites: []
    })
    setSelectedSites([])
    setIsChainWide(false)
    setErrorMessage(null)
  }

  const isformDisabled = () => {
    const isReason = formData.reason !== undefined && formData.reason.trim().length !== 0
    return submitting || !isReason || !areDatesSelected || selectedSites.length === 0
  }
  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={t('locations:title.addMultipleSiteClosingPeriod', 'Add Multiple site closing period')}
            backLabel={t('locations:labels.locations', 'Location settings')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <aside style={{gridArea: 'insert'}}>
              <InputField data-cy="closing-period" style={{marginBottom: '2rem'}}>
                <label htmlFor="closingPeriod">
                  <Text size="S">{t('settings:labels.columnNameClosingPeriod', 'Closing period')}</Text>
                </label>
                <DatePicker
                  id="closingPeriod"
                  onChange={onDateChange}
                  startDate={datePicker.startDate}
                  endDate={datePicker.endDate}
                  locale={locale}
                />
              </InputField>
              {errorMessage && (
                <Text size="S" style={{color: colors.system.red, margin: '1rem 0 1rem 0', display: 'block'}}>
                  {errorMessage}
                </Text>
              )}

              <InputField>
                <label htmlFor="instructions">
                  <Text size="S">{t('settings:labels.columnNameSiteOperations', 'Site operations')}</Text>
                </label>
                <Text size="XS" light>
                  {t(
                    'settings:descriptions.siteOperations',
                    'Generating alarms from appliance sensors and late tasks in Chefstein during closing period.'
                  )}
                </Text>
              </InputField>
              <SwitchRow>
                <Text size="S">{t('settings:labels.columnNameScheduledTasks', 'Scheduled tasks')}</Text>
                <Text size="S">{t('settings:description.scheduledTasksConfig', 'Off')}</Text>
              </SwitchRow>
              <SwitchRow>
                <SwitchTitle>
                  <Text size="S">{t('settings:labels.columnNameApplianceAlarms', 'Appliance alarms')}</Text>
                </SwitchTitle>
                <SwitchReversed
                  checked={formData.alarmsEnabled}
                  onChange={switchAlarmsEnabled}
                  style={{float: 'right'}}
                  disabled={submitting}
                />
              </SwitchRow>
              <ReasonInput>
                <Input
                  id="closingPeriodReason"
                  name="closingPeriodReason"
                  label={t('settings:labels.columnNameReason', 'Reason')}
                  placeholder={t('settings:input.reasonPlaceholder', 'Enter reason for closing the site.')}
                  multiline
                  rows={3}
                  onChange={changeReason}
                  value={formData.reason}
                  disabled={submitting}
                  required
                  data-cy={`suspended-period-input-reason`}
                />
              </ReasonInput>
              <Button
                style={{float: 'right'}}
                buttonStyle="primary"
                buttonSize="small"
                onClick={submit}
                disabled={isformDisabled()}
                data-cy={`suspended-period-submit-button`}
              >
                {t('settings:button.addPeriod', 'Add period')}
              </Button>
              <Button
                style={{float: 'right', marginRight: '1rem'}}
                buttonStyle="secondary"
                buttonSize="small"
                onClick={resetForm}
                disabled={submitting}
                data-cy={`suspended-period-reset-button`}
              >
                {t('settings:button.reset', 'Reset')}
              </Button>
            </aside>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <SiteSelectionCheckboxStyle
        infoText={t('locations:title.selectSitesToaddClosingPeriod', 'Select sites to add closing period')}
        writableIds={writableIds || []}
        chain={chain}
        isLoading={false}
        isChainWide={isChainWide}
        selectedSites={selectedSites}
        setIsChainWide={setIsChainWide}
        setSelectedSites={setSelectedSites}
        t={t}
      />
    </MainLayoutWithoutStretch>
  )
}

const SwitchRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
`
const SwitchTitle = styled.div`
  line-height: 2em;
`
const ReasonInput = styled.div`
  margin: 1rem 0 2rem 0;
`
const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 0.25rem;
`
export default MultipleSiteClosingPeriodPage
