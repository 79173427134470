import {WasteTaskType} from './../Components/Views/Tasks/CreateWasteTask'
import ky, {Options} from 'ky'
import authApi from './auth'
import {Me, Site, Chain, User, ChainPayload, Location, LocationGroup, Brand, NewsTicker} from './state'
import {ApiVersionError} from '../config/utils'
import {API_VERSION} from './actions'
import {PerformableTask} from './performTask/state'
import {v4 as uuid} from 'uuid'
import {UmbrellaCategory} from './home/site/state'
import {AvailableSensorDTO, LoraWANSensors} from './settings/appliances/state'

const APIURL = process.env.REACT_APP_BE_URL

interface ChefsteinResponse<T> {
  data: T
  count?: number
}

interface RequestArgs {
  path: string
  site?: string
  payload?: any
  checkApiVersion?: boolean
  testDashboard?: boolean
  contentTypes?: boolean
}

export const baseApi = {
  async getToken() {
    try {
      const token = await authApi.getToken()
      return token
    } catch (e) {
      console.error(e)
      throw Error('something went wrong getting the token? ' + JSON.stringify(e, null, 2))
    }
  },

  async doRequest<R>({path, site, payload, checkApiVersion, contentTypes}: RequestArgs) {
    const chainHeader = localStorage.getItem('selectedChainId')
    const contentType =
      contentTypes == undefined
        ? 'application/json'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const headers = new Headers({'Content-Type': contentType, 'x-chefstein-token': await this.getToken()})
    if (chainHeader) {
      headers.append('x-chefstein-chain', chainHeader)
    }
    if (site) {
      headers.append('x-chefstein-site', site)
    }
    headers.append('x-correlation-id', uuid())
    const options: Options = {
      headers,
      json: payload,
      timeout: 1000 * 60,
      throwHttpErrors: false
    }
    const url = APIURL + path
    return {
      async handleBody(r: Response) {
        const ok = r && r.ok
        const status = r ? r.status : -1
        let json
        try {
          json = await r.json()
        } catch (e) {
          // json parsing failure
          json = ok ? {} : {error: {status}}
        }
        return ok ? Promise.resolve(json) : Promise.reject(json)
      },
      get() {
        return ky.get(url, options).then<ChefsteinResponse<R>>(async r => {
          if (r && r.headers) {
            const minApiVersion = r.headers.get('min-api-version')
            if (checkApiVersion && minApiVersion && API_VERSION < +minApiVersion) {
              throw new ApiVersionError(`${API_VERSION} < ${minApiVersion}`)
            }
          }
          return await this.handleBody(r)
        })
      },
      post() {
        return ky.post(url, options).then<ChefsteinResponse<R>>(async r => {
          return await this.handleBody(r)
        })
      },
      delete() {
        return ky.delete(url, options).then<ChefsteinResponse<R>>(async r => {
          return await this.handleBody(r)
        })
      },
      put() {
        return ky.put(url, options).then<ChefsteinResponse<R>>(async r => {
          return await this.handleBody(r)
        })
      }
    }
  }
}
/**
 * TODO do better way where payloads and responses are properly typed
 */

export interface Status {
  app: string
  version: string
  apiVersion: number
  minApiVersion: number
  commitHash: string
  buildDate: string
  environment: string
  started: string
  debug: {numberOfSocketClients: number; socketClients: []}
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export interface AnyTaskData {
  alarmsEnabled: boolean
  id: string
  status: TaskStatus
  name: string
  description?: string
  instructions?: string
  defaultSuggestions: string[]
  taskGroup: TaskGroup
  taskType: TaskType
  schedule?: ScheduleResponse
  scheduleInstances?: {scheduledAt: string}[]
  scheduleInstance?: string
  target?: string
  events?: PossibleEvent[]
  sites: Site[]
  assets?: AssetData[]
  equipmentName?: string
  minValue?: number
  maxValue?: number
  unit?: string
  links?: TaskLink[]
  wasteTaskType?: WasteTaskType
  showUntilCompleted: boolean
}

interface TaskLink {
  id: string
  url: string
  description: string
}
export interface EventPayload {
  task: PerformableTask
  suggestions?: string[]
  remarks?: string
  comment?: string
  photoUrl?: string
  videoUrl?: string
  scheduledAt?: string
  value?: number | string
  choice?: string
  doneSubtasks?: string[]
  ingredients?: string[]
  asset?: AssetData
  status?: 'REJECTED'
  incompleteToDo?: boolean
  reportedActor?: string | string[]
  actorSuggestions?: string[]
}
export interface EventUpdatePayload {
  suggestions?: string[]
  remarks?: string
  comment?: string
  photoUrl?: string
  videoUrl?: string
  value?: number
  doneSubtasks?: string[]
  asset?: AssetData
  status?: 'REJECTED'
}

export type AllTasks = TodoTaskResponse &
  WasteTaskResponse &
  CoolingTaskResponse &
  TemperatureTaskResponse &
  HygieneTaskResponse &
  SingleChoiceTaskResponse &
  ManualTaskResponse &
  EquipmentTemperatureTaskResponse

export interface AnyTaskPayload {
  name: string
  description: string
  instructions: string
  defaultSuggestions: string[]
  taskGroupId: string
  taskTypeId: TaskTypeKey
  timeLimit?: number
  alarmLimit?: number
  minValue?: number
  maxValue?: number
  target?: string
  unit?: string
  defaultIngredients?: string
  measurementMethodId?: string
  schedule?: SchedulePayload
  equipmentName?: string
  assets?: AssetData[]
  links?: TaskLink[]
}

export interface ScheduleResponse {
  dates: string
  id: string
  monthdays?: string[]
  period: {
    [key: string]: number
  }
  time: string
  weekdays?: string[]
  yeardays?: string[]
}

export interface SchedulePayload {
  dates: string
  period: string | {[key: string]: number}
  weekdays?: number[]
  yeardays?: string[]
  monthdays?: number[]
  time: string
  id?: string
}

export interface PossibleFormFields {
  name: string
  description: string
  instructions: string
  defaultSuggestions: string[]
  timeLimit?: number | null
  alarmLimit?: number | null
  minValue?: number | null
  maxValue?: number | null
  target?: string
  unit?: string
  defaultIngredients?: string
  equipmentName?: string
  linkDescription?: string
  linkUrl?: string
  wasteTaskType?: WasteTaskType
  automaticReminder?: boolean
}
export interface AnyTasksResponse {
  items: AllTasks[]
  total: number
}
export interface SingleTasksResponse {
  data: AnyTaskData
}

interface OkResponse {
  message: string
}

export interface HygieneTaskResponse extends AnyTaskData {
  maxValue?: number
  unit: string
  alarmLimit: number
  ingredientSuggestions: string[]
  measurementMethod: MeasurementMethod
}

export interface WasteTaskResponse extends AnyTaskData {
  // unit: string
  alarmLimit: number
  ingredientSuggestions: string[]
  wasteTaskType: WasteTaskType
  automaticReminder?: boolean
}

export interface TodoTaskResponse extends AnyTaskData {
  subTasks?: string[]
}

export interface SingleChoiceTaskResponse extends AnyTaskData {
  choices: string[]
}

export interface CoolingTaskResponse extends AnyTaskData {
  minValue?: number
  maxValue?: number
  timeLimit: number
  measurementMethod: MeasurementMethod
}

export interface TemperatureTaskResponse extends AnyTaskData {
  minValue?: number
  maxValue?: number
  measurementMethod: MeasurementMethod
}

export interface EquipmentTemperatureTaskResponse extends AnyTaskData {
  minValue?: number
  maxValue?: number
  equipmentName: string
  measurementMethod: MeasurementMethod
}

export interface ManualTaskResponse extends AnyTaskData {
  minValue?: number
  maxValue?: number
  unit: string
}

export enum EventStatus {
  STARTING = 'STARTING',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED'
}
export interface EventBase {
  id: string
  status: EventStatus
  photoUrl?: string
  videoUrl?: string
  suggestions: string[]
  comment?: string
  task: AnyTaskData & Partial<AllTasks>
  createdAt: string
  updatedAt: string
  actor: EventActor
  reportedActor?: string
  remarks?: string
  scheduledAt?: string
  asset?: AssetData
  siteId?: string
}
export interface EventActor {
  id: string
  email: string
  firstName: string
  lastName: string
}

export type EventAsset = {
  section: string
  asset: AssetData
}

export interface EventForm {
  id?: string
  status: EventStatus
  photoUrl?: string
  videoUrl?: string
  suggestions: string[]
  comment?: string
  task: AnyTaskData & Partial<AllTasks>
  createdAt?: string // TODO should this be here?
  value?: string
  ingredients: string[]
}

export interface WasteEvent extends EventBase {
  value: number
  ingredients: string[]
}
export interface MeasurementEvent extends EventBase {
  value: number
}

export interface SingleChoiceEvent extends EventBase {
  choice: string
}
export interface TodoEvent extends EventBase {
  doneSubtasks: string[]
}

export interface CoolingEvent extends EventBase {
  coolingValues: CoolingValue[]
  cycleLog?: CycleLog
  createdByEquipmentId?: string
}

export interface CoolingValue {
  id: string
  timestamp: string
  value: number
  event: CoolingEvent
}

export type PossibleEvent = TodoEvent & CoolingEvent & MeasurementEvent & WasteEvent & SingleChoiceEvent

export type PossibleSensor = SensorAlarmResponse

export interface AnyAlarmResponse {
  items: PossibleAlarm[]
}

export type PossibleAlarm = TaskAlarmResponse

export enum AlarmType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  COOLINGTIME = 'COOLINGTIME',
  BATTERY = 'BATTERY',
  CALIBRATION = 'CALIBRATION',
  DISCONNECT = 'DISCONNECT',
  SENSOR = 'SENSOR'
}

export interface AnyAlarmData {
  id: string
  alarmType: AlarmType
  alarmValue: number | string
  alarmThreshold: number
  createdAt: string
  startedAt: string
  resolvedAt: string
  event: PossibleEvent | null
  sensor: PossibleSensor | null
  sensorChannel: SensorChannel | null
  resolution?: string
  lastMeasurement?: number
}

export interface TaskAlarmResponse extends AnyAlarmData {}

export interface SensorAlarmResponse {
  id: string
  sensorId: string
  batteryLevel?: number
  gateway?: Gateway
}

export enum AlarmMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
  // PUSH = 'PUSH' // disable push notification option until it's available
}

export enum FixedEquipmentTypes {
  Storage = 'storage',
  Freezer = 'freezer',
  WasteScale = 'waste-scale',
  Dishwasher = 'dishwasher',
  Cooler = 'cooler'
}

export interface AlarmSetting {
  id: string
  name: string
  delayInMin: number
  alarmMethods: AlarmMethod[]
  sites: Site[]
  recipients: Recipient[]
  extRecipients: Recipient[]
  schedules: WeekSchedule[]
  equipmentTypes: FixedEquipmentTypes[]
}

export interface AlarmSettingsResponse {
  items: AlarmSetting[]
}

export interface Recipient {
  id: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  language?: string
}

export interface RecipientsResponse {
  items: Recipient[]
}

export interface WeekSchedule {
  id?: string
  from?: string
  to?: string
  weekdays?: number[]
}

export interface UsersResponse {
  items: User[]
}

export type FileUploadData = {
  uploadURL: string
  filePath: string
}

export type FileUploadResponse = {
  data: FileUploadData
}

export type FileDownloadData = {
  downloadURL: string
}

export type FileUploadPayload = {
  fileType: string
  fileName: string
  instructions?: boolean
}

export enum AssetType {
  PHOTO = 'photo',
  VIDEO = 'video',
  DOCUMENT = 'document'
}

export type AssetData = {
  id: string
  name: string
  assetPath: string
  assetSize: number
  assetMimeType: string
  assetType: AssetType
  createDateTime: Date
  chain?: Chain
  tasks?: AnyTaskData[]
}

export type AssetPayload = {
  name: string
  assetPath: string
  assetSize: number
  assetMimeType: string
  assetType: AssetType
  chain?: Chain
  task?: AnyTaskData
  instructions?: boolean
}

export interface AssetResponse {
  items: AssetData[]
}

export enum DocumentFolderType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type DocumentFolder = {
  id: string
  type: string
  name: string
  url?: string
  description?: string
  chain?: Partial<Chain>
}

export enum DocumentType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type Document = {
  id: string
  type: string
  name: string
  description?: string
  createdAt: string
  createdBy: User
  asset?: Partial<AssetData>
  url?: string
  documentFolder?: Partial<DocumentFolder>
  sites: DocumentToSites[] | undefined
}

export type DocumentToSites = {
  id: string
  siteId: string
  documentId: string
}

export type DocumentGroup = {
  type: string
  name: string
  id: string
  description?: string
  url?: string
  chain?: Partial<Chain>
  documents: Document[]
}
export interface DocumentResponse {
  items: Document[]
}
export interface DocumentFolderResponse {
  items: DocumentFolder[]
}

export type Gateway = {
  id: string
  outletId: string
  name: string
  site: Partial<Site>
}

export type SensorValue = {
  value: number
  receivedAt: string
}

export enum SensorMetadataKey {
  CALIBRATION_DATE = 'calibrationDate',
  DEVICE_TYPE = 'deviceType',
  FIRMWARE_VERSION = 'firmwareVersion',
  MAIN_BATTERY_LEVEL = 'mainBatteryLevel',
  SERIAL_NUMBER = 'serialNumber',
  SIGNAL_STRENGTH = 'signalStrength',
  SUBTYPE = 'subtype'
}

export type SensorMetadata = {
  timestamp: string
  value: string
}

export type Sensor = {
  id: string
  sensorId: string
  gateway?: Partial<Gateway>
  batteryLevel?: number
  calibrationDate?: Date
  serialNumber?: string
}

export type SensorChannel = {
  id: string
  channel: string
  sensor: Sensor
  equipment?: Partial<Equipment>
  latestValue?: SensorValue
  type?: string
  cycleStartedAt?: string
  cycleEndedAt?: string
  sensorChannelRole?: SensorRole
}

export interface SensorChannelResponse {
  items: SensorChannel[]
}

export type SensorMetadataResponse = {
  [key in SensorMetadataKey]?: SensorMetadata
}

export enum SensorRole {
  singleSensor = '', // use case: only one nokeval single sensor appliances
  default = 'default', //use case: only for lorawan single sensor appliances
  prewash = 'prewash',
  wash = 'wash',
  rinse = 'rinse',
  coolerFast = 'cooler-fast',
  coolerSlow = 'cooler-slow'
}

export interface WasteScaleCategory {
  name: string
  id: string
}

export interface SensorValuesResponse {
  items: {value: number; timestamp: string}[]
}
export interface ComplexSensorValuesResponse {
  items: {
    value?: number
    value_avg: number
    value_median: number
    value_max: number
    value_min: number
    date: string
    timestamp?: string
  }[]
}
export type SuspendedEvent = {
  id: string
  suspended: boolean
  reason?: string
  updatedBy?: string
  updatedAt: string
}

export type Equipment = {
  id: string
  name: string
  model?: string
  installationYear?: number
  sensorEnabled: boolean
  equipmentType?: Partial<EquipmentType>
  storageType?: Partial<StorageType>
  sensorChannels?: SensorChannel[]
  site?: Site
  suspended: boolean
  suspendedEvents?: SuspendedEvent[]
  wasteScaleCategory?: {name?: string; id?: string}
  dishwasherType?: Partial<DishwasherType>
  dishwasherCycleInfo?: Partial<DishwasherCycleInfo>
  cycleLog?: CycleLog[]
  coolerType?: Partial<CoolerType>
  activeAlarms?: number
  updatedBy?: string
  reason?: string
  sensors?: LoraWANSensors[]
}

// TODO: Remove id and reason from this type
export type PostEquipment = {
  id: string
  name: string
  model?: string
  installationYear?: number
  sensorEnabled: boolean
  equipmentType?: string
  storageType?: string
  sensorChannels?: SensorChannel[]
  site?: Site
  suspended: boolean
  suspendedEvents?: SuspendedEvent[]
  wasteScaleCategory?: string
  dishwasherType?: string
  dishwasherCycleInfo?: Partial<DishwasherCycleInfo>
  cycleLog?: CycleLog[]
  coolerType?: string
  activeAlarms?: number
  updatedBy?: string
  reason?: string
  sensors?: AvailableSensorDTO[]
}

export interface EquipmentResponse {
  items: Equipment[]
}

export enum EquipmentTypeKey {
  STORAGE = 'storage',
  FREEZER = 'freezer',
  WASTE = 'waste-scale',
  DISHWASHER = 'dishwasher',
  COOLER = 'cooler',
  OVEN = 'oven'
}

export type EquipmentType = {
  id: string
  name: string
  fixed: boolean
  description?: string
  icon?: string
  chain?: Partial<Chain>
}
export interface EquipmentTypeResponse {
  items: EquipmentType[]
}

export enum StorageSensorType {
  TEMPERATURE = 'TEMPERATURE',
  TEMPERATURE_HUMIDITY = 'TEMPERATURE_HUMIDITY'
}

export type StorageType = {
  id: string
  name: string
  minValue: number
  maxValue: number
  timeLimit: number
  equipmentType?: Partial<EquipmentType>
  chain?: Partial<Chain>
  sensorType?: StorageSensorType
  humidityMinValue?: number
  humidityMaxValue?: number
  humidityTimeLimit?: number
}

export interface StorageTypeResponse {
  items: StorageType[]
}

export type DishwasherType = {
  id: string
  sequenceType: string
  rinseMinValue?: number
  rinseMaxValue?: number
  washMinValue?: number
  washMaxValue?: number
  prewashMinValue?: number
  prewashMaxValue?: number
}

export interface DishwasherTypeResponse {
  items: DishwasherType[]
}

export type CycleLog = {
  id: string
  startedAt: string
  endedAt: string
  firstMeasurement: number
  lastMeasurement: number
  cycleTimeLimit: number
  startLimit: number
  targetLimit: number
  alarmThreshold: number
}

export type DishwasherCycleInfo = {
  alarmDelayCycles: number
  alarmSensorRoles: SensorRole[]
}

export type CoolerType = {
  id: string
  cycleType: string
  slowCycleTargetValue?: number
  slowCycleStartValue?: number
  slowCycleTimeLimit?: number
  fastCycleTargetValue?: number
  fastCycleStartValue?: number
  fastCycleTimeLimit?: number
}

export interface CoolerTypeResponse {
  items: CoolerType[]
}

export interface AuthTicket {
  ticket: string
}
export interface AuthTicketResponse {
  data: AuthTicket
}

export interface ChainResponse {
  items: Chain[]
}

export interface NewsTickerResponse {
  items: NewsTicker[]
}

export interface SensorRequestPayload {
  id: string // this is SensorChannel.id
  from: string
  to: string
  view?: string
}

export interface GatewayWithSensors {
  gateway: {
    id: string
    outletId: string
    name: string
  }
  sensors: {
    id: string
    sensorId: string
    batteryLevel: number
    calibrationDate: Date
    serialNumber: string
    modelName: string
    metadata: SensorMetadataResponse
  }[]
}

export interface DevicesForSite {
  siteId: string
  devices: GatewayWithSensors[]
}

export enum UserLanguage {
  en = 'en',
  fi = 'fi',
  sv = 'sv',
  pl = 'pl'
}

export interface DashboardResponse {
  data: DashboardData
}
export interface DashboardData {
  embedUrl: string
}

export const appStatusApi = {
  ...baseApi,
  async checkStatus() {
    return ky.get(`${APIURL}/status`, {}).then(async resp => {
      const minApiVersion: number = +(resp?.headers?.get('min-api-version') || 0)
      return {
        ...(await resp.json()),
        minApiVersion
      }
    })
  },
  async getNewsTickers() {
    const request = await this.doRequest<ChainResponse>({path: '/newstickers/'})
    const resp = await request.get()
    return resp.data
  }
}
export const userApi = {
  ...baseApi,
  async getMeData() {
    const request = await this.doRequest<Me>({path: '/me/'})
    const resp = await request.get()
    return resp.data
  },
  async createUser(payload: any, site: Site) {
    const req = await this.doRequest<OkResponse>({path: `/users/`, site: site.id, payload})
    const resp = req.post()
    return resp
  },
  async updateUser(id: string, payload: any) {
    const req = await this.doRequest<OkResponse>({path: `/users/${id}`, payload})
    const resp = req.put()
    return resp
  },
  async getUsers() {
    const request = await this.doRequest<UsersResponse>({path: '/users/?limit=999999'})
    const resp = await request.get()
    return resp.data
  },
  async getUser(userId: string) {
    const request = await this.doRequest<User>({path: `/users/${userId}`})
    const resp = await request.get()
    return resp.data
  },
  async resetPassword(userId: string) {
    const request = await this.doRequest<User>({path: `/users/${userId}/resetpassword`})
    const resp = await request.post()
    return resp.data
  },
  async removeUser(userId: string) {
    const request = await this.doRequest<User>({path: `/users/${userId}`})
    const resp = await request.delete()
    return resp.data
  },
  async notifyUser(identityId: string) {
    const request = await this.doRequest<OkResponse>({path: `/users/notify`, payload: {identityId}})
    const resp = await request.post()
    return resp.data
  },
  async getAromiLinkForChain(id: string) {
    const req = await this.doRequest<string>({path: `/chains/aromiLink/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async downloadUserList() {
    try {
      const request = ky
        .get(`${APIURL}/users/userList`, {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'x-chefstein-token': await this.getToken()
          }
        })
        .then(async response => {
          return await response.json()
        })
      return request
    } catch (error) {
      console.error('Error downloading user list:', error)
      throw error
    }
  }
}

export const adminApi = {
  ...baseApi,
  async getChains() {
    const request = await this.doRequest<ChainResponse>({path: '/chains/'})
    const resp = await request.get()
    return resp.data
  },
  async getChain(id: string) {
    const req = await this.doRequest<Chain>({path: `/chains/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createChain(payload: ChainPayload) {
    const req = await this.doRequest({path: '/chains/', payload})
    const resp = await req.post()
    return resp
  },
  async createOrganization() {
    const req = await this.doRequest({path: '/admin/organization'})
    const resp = await req.post()
    return resp
  },
  async deleteChain(id: string) {
    const req = await this.doRequest({path: `/chains/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateChain(payload: Chain) {
    const req = await this.doRequest({path: `/chains/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getNewsTickers() {
    const request = await this.doRequest<NewsTickerResponse>({path: '/newsticker/'})
    const resp = await request.get()
    return resp.data
  },
  async updateNewsTicker(payload: NewsTicker) {
    const req = await this.doRequest({path: `/newsticker/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getGatewaysAndSensorsForChain() {
    const chainId = localStorage.getItem('selectedChainId')
    if (chainId) {
      const req = await this.doRequest<DevicesForSite[]>({path: `/admin/gateways/chains/${chainId}`})
      const resp = await req.get()
      return resp.data
    } else {
      return []
    }
  },
  async getGatewaysAndSensorsForSite(siteId: string) {
    const req = await this.doRequest<GatewayWithSensors[]>({path: `/admin/gateways/sites/${siteId}`})
    const resp = await req.get()
    return resp.data
  },
  async updateGatewaysAndSensorsForSite(siteId: string, data: GatewayWithSensors[]) {
    interface SensorDto {
      id: string
      sensorId: string
      model: string
    }
    interface NewGatewayDto {
      id: string
      outletId: string
      name: string
      sensors: SensorDto[]
    }

    const payload: NewGatewayDto[] = data.map(d => ({
      id: d.gateway.id,
      outletId: d.gateway.outletId,
      name: 'no name',
      sensors: (d.sensors || []).map(s => ({
        id: s.id,
        sensorId: s.sensorId,
        model: s.modelName || 'no name'
      }))
    }))
    const req = await this.doRequest<DevicesForSite>({path: `/admin/gateways/sites/${siteId}`, payload})
    const resp = await req.put()
    return resp
  },
  async getSensorModels() {
    const req = await this.doRequest<string[]>({path: `/admin/gateways/sensorModels`})
    const resp = await req.get()
    return resp.data
  }
}
interface SitePayload {
  location: Partial<Location>
  site: Partial<Site>
  id?: string
}

export const organisationApi = {
  ...baseApi,
  async getSite(id: string) {
    const request = await this.doRequest<Site>({path: '/organisation/site/' + id})
    const resp = await request.get()
    return resp.data
  },
  async createSite(payload: SitePayload) {
    const req = await this.doRequest({path: '/organisation/site', payload})
    const resp = await req.post()
    return resp
  },
  async createLocation(payload: Partial<Location>) {
    const req = await this.doRequest({path: `/v1/organisation/location`, payload})
    const resp = await req.post()
    return resp
  },
  async deleteSite(id: string) {
    const req = await this.doRequest({path: `/organisation/site/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateSite(payload: Site) {
    const req = await this.doRequest<Site>({path: `/organisation/site/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async updateLocation(payload: Location) {
    const req = await this.doRequest({path: `/v1/organisation/location/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async updateLocationGroup(payload: LocationGroup) {
    const req = await this.doRequest({path: `/organisation/group/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async updateBrand(payload: Brand) {
    const req = await this.doRequest({path: `/organisation/brand/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  }
}
export const graphApi = {
  ...baseApi,
  async getTableauTicket() {
    const request = await this.doRequest<AuthTicket>({path: '/tableau/ticket'})
    const resp = await request.post()
    return resp
  }
}

export const alarmsApi = {
  ...baseApi,
  async getAlarmSettings() {
    const path = '/alarmsettings'
    const req = await this.doRequest<AlarmSettingsResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getAlarmSetting(id: string) {
    const req = await this.doRequest<AlarmSetting>({path: `/alarmsettings/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createAlarmSetting(payload: Partial<AlarmSetting>) {
    const req = await this.doRequest({path: '/alarmsettings', payload})
    const resp = await req.post()
    return resp
  },
  async deleteAlarmSetting(id: string) {
    const req = await this.doRequest({path: `/alarmsettings/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateAlarmSetting(payload: Partial<AlarmSetting>) {
    const req = await this.doRequest({path: `/alarmsettings/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getRecipients() {
    const request = await this.doRequest<RecipientsResponse>({path: '/users/?limit=999999'})
    const resp = await request.get()
    return resp.data
  }
}

export const equipmentApi = {
  ...baseApi,
  async getEquipmentSettings() {
    const path = '/v1/settings/appliances'
    const req = await this.doRequest<EquipmentResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getEquipment(id: string, site: Site, siteId?: string) {
    const req = await this.doRequest<Equipment>({path: `/v1/applianceSettings/${id}`, site: siteId ? siteId : site.id})
    const resp = await req.get()
    return resp.data
  },
  async createEquipment(payload: Partial<PostEquipment>, site: Site) {
    const req = await this.doRequest({path: '/v1/applianceSettings', site: site.id, payload})
    const resp = await req.post()
    return resp.data
  },
  async deleteEquipment(id: string, siteId: string) {
    const req = await this.doRequest({path: `/v1/applianceSettings/${id}`, site: siteId})
    const resp = await req.delete()
    return resp.data
  },

  async getEquipmentTypes() {
    const path = '/equipments/types'
    const req = await this.doRequest<EquipmentTypeResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getEquipmentType(id: string) {
    const req = await this.doRequest<EquipmentType>({path: `/equipments/types/${id}`})
    const resp = await req.get()
    return resp.data as EquipmentType
  },
  async createEquipmentType(payload: Partial<EquipmentType>) {
    const req = await this.doRequest<EquipmentType>({path: '/equipments/types', payload})
    const resp = await req.post()
    return resp.data as EquipmentType
  },
  async deleteEquipmentType(id: string) {
    const req = await this.doRequest({path: `/equipments/types/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateEquipmentType(payload: EquipmentType) {
    const req = await this.doRequest({path: `/equipments/types/${payload.id}`, payload})
    const resp = await req.put()
    return resp.data as EquipmentType
  },
  async getStorageTypes() {
    const path = '/equipments/storagetypes'
    const req = await this.doRequest<StorageTypeResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getStorageType(id: string) {
    const req = await this.doRequest<StorageType>({path: `/equipments/storagetypes/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createStorageType(payload: Partial<StorageType>) {
    const req = await this.doRequest({path: '/equipments/storagetypes', payload})
    const resp = await req.post()
    return resp
  },
  async deleteStorageType(id: string) {
    const req = await this.doRequest({path: `/equipments/storagetypes/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateStorageType(payload: StorageType) {
    const req = await this.doRequest({path: `/equipments/storagetypes/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getDishwasherTypes() {
    const path = '/equipments/dishwashertypes'
    const req = await this.doRequest<DishwasherTypeResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async updateDishwasherType(payload: DishwasherType) {
    const req = await this.doRequest({path: `/equipments/dishwashertypes/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getCoolerTypes() {
    const path = '/equipments/coolertypes'
    const req = await this.doRequest<CoolerTypeResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async updateCoolerType(payload: CoolerType) {
    const req = await this.doRequest({path: `/equipments/coolertypes/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getSensorChannels(site: Site) {
    const path = '/sensors'
    const req = await this.doRequest<SensorChannelResponse>({path, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async getFreeSensorChannels(site: Site) {
    const path = '/v1/applianceSettings/availableSensors'
    const req = await this.doRequest<SensorChannelResponse>({path, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async getWasteScaleCategories() {
    const path = '/equipments/wastescalecategories'
    const req = await this.doRequest<{items: WasteScaleCategory[]}>({path})
    const resp = await req.get()
    return resp.data
  },
  async createWasteScaleCategories(name: string) {
    const path = '/equipments/wastescalecategories'
    const req = await this.doRequest<{items: WasteScaleCategory[]}>({path, payload: {name}})
    const resp = await req.post()
    return resp.data
  },
  async updateWasteScaleCategories(id: string, name: string) {
    const path = '/equipments/wastescalecategories/' + id
    const req = await this.doRequest<WasteScaleCategory>({path, payload: {name}})
    const resp = await req.put()
    return resp.data
  },
  async deleteWasteScaleCategories(id: string) {
    const path = '/equipments/wastescalecategories/' + id
    const req = await this.doRequest<WasteScaleCategory>({path})
    const resp = await req.delete()
    return resp.data
  }
}

export const taskGroupsApi = {
  ...baseApi,

  async getTaskGroups() {
    const req = await this.doRequest<{items: TaskGroup[]}>({path: '/taskgroups'})
    const resp = await req.get()
    return resp.data.items
  },

  async createTaskGroup(payload: Partial<TaskGroup>) {
    const req = await this.doRequest({path: '/v1/taskGroups', payload})
    const resp = await req.post()
    return resp
  },
  async updateTaskGroup(payload: TaskGroup) {
    const req = await this.doRequest({path: '/v1/taskGroups/' + payload.id, payload})
    const resp = await req.put()
    return resp
  },
  async deleteTaskGroup(id: string) {
    const req = await this.doRequest({path: '/taskgroups/' + id})
    const resp = await req.delete()
    return resp
  }
}

export const tasksApi = {
  ...baseApi,

  async getInitialData() {
    try {
      const request = await this.doRequest<InitialData>({path: '/categories/'})
      const partialData = await request.get()
      const taskGroups = await taskGroupsApi.getTaskGroups()
      return {...partialData.data, taskGroups}
    } catch (error) {
      console.log('error getting initial data')
      throw error
    }
  },
  async getTask(id: string, site: Site) {
    const req = await this.doRequest<AllTasks>({path: `/tasks/${id}`, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async add(payload: any, site: Site) {
    const req = await this.doRequest<OkResponse>({
      path: `/tasks/`,
      site: site.id,
      payload
    })
    const resp = await req.post()
    return resp
  },
  async delete(id: string, site: Site) {
    const req = await this.doRequest<OkResponse>({path: `/tasks/${id}`, site: site.id})
    const resp = req.delete()
    return resp
  },
  async update(id: string, site: Site, payload: any) {
    const req = await this.doRequest<OkResponse>({
      path: `/tasks/${id}`,
      site: site.id,
      payload
    })
    const resp = req.put()
    return resp
  }
}

export const assetApi = {
  ...baseApi,

  async createUploadUrl(payload: FileUploadPayload, site: Site) {
    const path = '/assets/fileuploadurl'
    const req = await this.doRequest<FileUploadData>({path, site: site.id, payload})
    const resp = await req.post()
    return resp.data
  },
  async getDownloadUrl(id: string) {
    const path = `/assets/${id}/download`
    const request = await this.doRequest<FileDownloadData>({path})
    const resp = await request.get()
    return resp.data
  },
  async getVideos(site: Site) {
    const path = '/assets/videos'
    const req = await this.doRequest<AssetResponse[]>({path, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async getPhotos(site: Site) {
    const path = '/assets/photos'
    const req = await this.doRequest<AssetResponse[]>({path, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async getAsset(id: string, site: Site) {
    const req = await this.doRequest<AssetResponse>({path: `/assets/${id}`, site: site.id})
    const resp = await req.get()
    return resp.data
  },
  async add(payload: any, site: Site): Promise<AssetData> {
    const req = await this.doRequest<AssetData>({path: `/assets/`, site: site.id, payload})
    const resp = await req.post()
    return resp.data
  },
  async delete(id: string, site: Site) {
    const req = await this.doRequest<OkResponse>({path: `/assets/${id}`, site: site.id})
    const resp = req.delete()
    return resp
  },
  async update(id: string, site: Site, payload: any) {
    const req = await this.doRequest<AssetData>({path: `/assets/${id}`, site: site.id, payload})
    const resp = req.put()
    return resp
  }
}

export const dashboardApi = {
  ...baseApi,

  async getDashboardData(isTestDashboard: boolean = false) {
    try {
      const req = await this.doRequest<DashboardData>({path: `/quicksight/${isTestDashboard}`})
      const resp = await req.get()
      return resp.data
    } catch (error) {
      console.error('Error in getDashboardData:', error)
      throw error // Rethrow the error for further handling
    }
  }
}

export type BaseQuery = {
  taskTypes: TaskType[]
  measurementMethods: MeasurementMethod[]
  taskGroups: TaskGroup[]
}
export type InitialData = {
  taskTypes: TaskType[]
  measurementMethods: MeasurementMethod[]
}

export type TaskTypeKey =
  | 'temperature'
  | 'hygiene'
  | 'todo'
  | 'waste'
  | 'cooling'
  | 'automaticCooling'
  | 'manual'
  | 'singlechoice'
  | 'equipmentTemperature'

export enum TaskTypeEnum {
  TEMPERATURE = 'temperature',
  HYGIENE = 'hygiene',
  TODO = 'todo',
  WASTE = 'waste',
  COOLING = 'cooling',
  AUTOMATIC_COOLING = 'automaticCooling',
  MANUAL = 'manual',
  SINGLE_CHOICE = 'singlechoice',
  EQUIPMENT_TEMPERATURE = 'equipmentTemperature'
}

export type MeasurementType = 'device' | 'manual'

export type TaskType = {
  name: string
  id: TaskTypeKey
  description: string
  taskTypeGroups: TaskTypeGroup[]
  visible: boolean
}

export type TaskTypeGroup = {
  default: boolean
  taskGroup: TaskGroup
}

export type MeasurementMethod = {
  name: string
  id: MeasurementType
}

export type TaskGroup = {
  name: string
  id: string
  icon?: string
  fixed: boolean
  umbrellaCategory: UmbrellaCategory
}
