import React, {memo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {H3} from '../../../Atoms/Typography'
import {useNavigation} from 'react-navi'
import {Baseline, Row} from '../../../Layout/Grid'
import {useAppState} from '../../../../state'
import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {A11yAccordion} from '../../../Atoms/Accordion'
import {IconLocation} from '../../../../Assets/Icons/LargeIcons'
import {Chain, Site} from '../../../../state/state'
import {HeaderRow, GoBackHeaderTitle, HeaderActions} from '../../../Molecules/ViewComponents/ViewHeader'
import {A11yListItem, A11yListContainer, OffsetListItem, A11yIconListItemWithDetails} from '../../../Atoms/Lists'
import {IconMenuSettings} from '../../../../Assets/Icons/MenuIcons'
import {IconListItem} from '../../../Molecules/TaskListItem'
import {Helmet} from 'react-navi-helmet-async'
import {availableKeyValues} from '../../../Views/Utils'
import LocationsMenuModal from './LocationsMenuModal'

const EditTasks = memo(() => {
  const {state} = useAppState()

  const isSuperUser = state.me?.accessRights.superuser === true
  const nav = useNavigation()
  const {t} = useTranslation(['locations', 'settings'])
  const [modalOpen, setModalOpen] = useState(false)
  const handleClick = (type: string, id: string) => {
    nav.navigate(`/settings/locations/edit/${type}/${id}`)
  }
  const chain = state.chainsById[state.selectedChainId!]
  const isChainManager = state.me?.user.userToSite.some(
    userToSite => userToSite.userRole === 'CM' && userToSite.chainId === chain?.id
  )

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.locations', 'Location settings')} />
      <MainContent variant="white">
        <Baseline>
          <HeaderRow
            description={t(
              'locations:messages.viewDescription',
              'In locations view you can add, view and edit the sites representing your kitchens. You can also define the organisation levels, which allow you to group your sites and define permissions for specific levels.'
            )}
            modal={
              <LocationsMenuModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onLocationAdd={() => nav.navigate('/settings/locations/add/locations')}
                onSiteAdd={() => nav.navigate('/settings/locations/add')}
                onMultipleSiteClosingPeriod={() => nav.navigate('/settings/locations/addMultipleSiteClosingPeriod')}
              />
            }
          >
            <GoBackHeaderTitle
              label={t('locations:labels.locations', 'Location settings')}
              path={'/settings'}
              backLabel={t('settings:labels.settingsTitle', 'Settings')}
            />
            {state.me?.accessRights.superuser && <HeaderActions onMenu={() => setModalOpen(true)} />}
          </HeaderRow>
          <A11yListContainer>
            <LocationList
              key={chain.id}
              chain={chain}
              isSuperUser={isSuperUser}
              onOrgSelect={handleClick}
              isChainManager={isChainManager}
            />
          </A11yListContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
})

interface LocationProps {
  chain: Chain
  isSuperUser: boolean
  onOrgSelect: (type: string, id: string) => void
  isChainManager?: boolean
}

interface OrgLevel {
  name: string
  description?: string
  visible: boolean
  locationGroups?: OrgLevel[]
  locations?: OrgLevel[]
  brands?: OrgLevel[]
  sites?: Site[]
  id: string
  chains?: OrgLevel[]
}

interface SiteListProps {
  data: OrgLevel
  prevOffset: number
  isSuperUser: boolean
  onOrgSelect: (key: string, id: string) => void
}

const SiteList = ({data, prevOffset, isSuperUser, onOrgSelect}: SiteListProps) => {
  const keys = Object.keys(data) as Array<keyof OrgLevel>
  const arr = keys.find(k => availableKeyValues.includes(k)) as
    | 'chains'
    | 'locationGroups'
    | 'locations'
    | 'brands'
    | 'sites'
  const offset = data.visible ? 2 + prevOffset : prevOffset
  let current: string
  const {actions} = useAppState()
  switch (arr) {
    case 'chains':
      current = 'chains'
      break
    case 'locationGroups':
      current = 'brands'
      break
    case 'locations':
      current = 'locationGroups'
      break
    case 'sites':
      current = 'locations'
      break
    default:
      current = 'sites'
      break
  }
  const {state} = useAppState()
  const getGatewaysForSiteId = (id: string): string => {
    const item = state.gatewaysWithSensorsForSites.find(i => i.siteId === id)
    if (item) {
      return item.devices.map(d => d.gateway.outletId).join(', ')
    } else {
      return ''
    }
  }

  return !!arr && arr !== 'sites' ? (
    data.visible ? (
      <A11yAccordion
        disableSelectedBorder
        title={data.name}
        storeId={data.id}
        header={
          <OffsetListItem offset={offset}>
            <H3>{data.name}</H3>
            {actions.hasUserWriteRights(data.id) && <IconMenuSettings onClick={() => onOrgSelect(current, data.id)} />}
          </OffsetListItem>
        }
      >
        {data[arr]!.map(d => (
          <SiteList key={d.id} data={d} prevOffset={offset} isSuperUser={isSuperUser} onOrgSelect={onOrgSelect} />
        ))}
      </A11yAccordion>
    ) : (
      <>
        {data[arr]!.map(d => (
          <SiteList key={d.id} data={d} prevOffset={offset} isSuperUser={isSuperUser} onOrgSelect={onOrgSelect} />
        ))}
      </>
    )
  ) : data.visible ? (
    <>
      <A11yAccordion
        disableSelectedBorder
        title={data.name}
        storeId={data.id}
        header={
          <OffsetListItem offset={offset}>
            <H3>{data.name}</H3>
            {actions.hasUserWriteRights(data.id) && <IconMenuSettings onClick={() => onOrgSelect(current, data.id)} />}
          </OffsetListItem>
        }
      >
        {data.sites!.map(data => (
          <A11yIconListItemWithDetails
            offset={offset + 2}
            onClick={() => onOrgSelect('site', data.id)}
            key={data.id}
            icon={<IconLocation />}
            content={<H3>{data.name}</H3>}
            details={isSuperUser && <H3>{getGatewaysForSiteId(data.id)}</H3>}
          />
        ))}
      </A11yAccordion>
    </>
  ) : (
    <>
      {data.sites!.map(data => (
        <A11yListItem onClick={() => onOrgSelect('site', data.id)} title={data.name} key={data.id}>
          <OffsetListItem offset={offset}>
            <div>
              <IconListItem bgColor="transparent" icon={<IconLocation />} content={<H3>{data.name}</H3>} />
            </div>
          </OffsetListItem>
        </A11yListItem>
      ))}
    </>
  )
}

const LocationList = ({chain, onOrgSelect, isSuperUser, isChainManager}: LocationProps) => {
  return (
    <A11yAccordion
      title={chain.name}
      storeId={chain.id}
      header={
        <Row justify="space-between">
          <H3>{chain.name}</H3>
          {isSuperUser || isChainManager ? <IconMenuSettings onClick={() => onOrgSelect('chain', chain.id)} /> : null}
        </Row>
      }
    >
      {chain.brands.map(b => (
        <SiteList key={b.id} data={b} prevOffset={0} isSuperUser={isSuperUser} onOrgSelect={onOrgSelect} />
      ))}
    </A11yAccordion>
  )
}

export default EditTasks
