import React, {useEffect} from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {HeaderTitle, HeaderRow, HeaderActions} from '../../../Molecules/ViewComponents/ViewHeader'
import DocumentList from '../DocumentList'
import {useAppState} from '../../../../state'

import {openInNewTab} from '../../../../config/utils'
import {Document} from '../../../../state/rest'
import {Helmet} from 'react-navi-helmet-async'
import {Loader} from '../../../../Routes'
import {ErrorMessage} from '../../../Molecules/Errors/ErrorMessage'

const DocumentsView = () => {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('documents')

  useEffect(() => {
    const setup = async () => {
      actions.v1.documents.getDocuments()
      actions.v1.documents.getDocumentFolders()
    }
    setup()
  }, [actions.v1.documents])

  const {
    documentGroups,
    documentsPending,
    documentFoldersPending,
    documentFoldersError,
    documentsError
  } = state.v1.documents

  const isLoading = documentsPending || documentFoldersPending
  const error = documentsError ? documentsError : documentFoldersError ? documentFoldersError : null

  const handleEdit = (id: string) => {
    nav.navigate('/documents/' + id)
  }

  const handleDownload = async (d: Document) => {
    if (d.url) {
      openInNewTab(d.url)
    } else if (d.asset && d.asset.id) {
      try {
        const data = await actions.getDownloadUrl(d.asset.id)
        openInNewTab(data.downloadURL)
      } catch (error) {
        // TODO display note about failure
      }
    }
  }

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.documents', 'Documents')} />
      <Loader show={isLoading} />
      {!isLoading && (
        <MainContent variant="white">
          <HeaderRow
            description={t(
              'documents:messages.viewDescription',
              'In documents view you can find work related documents in folders. You can add new folders, and add and edit folders with the toolbar on top, if your usage permissions allow.'
            )}
          >
            <HeaderTitle>{t('documents:labels.documentsTitle', 'Documents')}</HeaderTitle>
            {state.me &&
              (state.me.accessRights.write.sites.length > 0 || state.me.accessRights.write.chains.length > 0) && (
                <HeaderActions
                  onConfigure={() => nav.navigate('/documents/folders')}
                  onAdd={() => nav.navigate('/create/document')}
                />
              )}
          </HeaderRow>
          {error === null ? (
            <DocumentList documentGroups={documentGroups as any} onClick={handleDownload} onConfigure={handleEdit} />
          ) : (
            <ErrorMessage message={error.message} />
          )}
        </MainContent>
      )}
    </MainLayoutWithoutStretch>
  )
}

export default DocumentsView
